// Generated from /DSL/DSL.Library/graph.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';
import graphListener from './graphListener.js';
import graphVisitor from './graphVisitor.js';

const serializedATN = [4,1,110,1089,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,
7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,
2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,
20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,
7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,34,7,
34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,7,41,
2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,48,2,
49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,2,56,
7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,63,7,
63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,7,70,
2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,77,2,
78,7,78,2,79,7,79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,2,85,
7,85,2,86,7,86,2,87,7,87,2,88,7,88,2,89,7,89,2,90,7,90,2,91,7,91,2,92,7,
92,2,93,7,93,2,94,7,94,1,0,3,0,192,8,0,1,0,1,0,1,0,1,1,1,1,1,1,1,2,1,2,1,
2,1,3,5,3,204,8,3,10,3,12,3,207,9,3,1,3,5,3,210,8,3,10,3,12,3,213,9,3,1,
3,1,3,3,3,217,8,3,1,3,1,3,3,3,221,8,3,1,3,1,3,1,3,1,3,1,3,1,3,5,3,229,8,
3,10,3,12,3,232,9,3,1,4,1,4,1,4,1,4,5,4,238,8,4,10,4,12,4,241,9,4,1,4,1,
4,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,3,5,253,8,5,1,6,1,6,1,6,1,6,5,6,259,8,
6,10,6,12,6,262,9,6,1,6,1,6,1,7,1,7,3,7,268,8,7,1,7,1,7,1,7,1,7,1,7,3,7,
275,8,7,1,8,1,8,1,8,1,8,1,8,1,8,1,8,1,8,1,8,1,8,1,9,1,9,1,9,1,9,3,9,291,
8,9,1,9,1,9,1,10,1,10,1,10,1,10,1,10,1,10,1,10,5,10,302,8,10,10,10,12,10,
305,9,10,1,10,3,10,308,8,10,1,10,1,10,1,11,1,11,1,11,5,11,315,8,11,10,11,
12,11,318,9,11,3,11,320,8,11,1,11,3,11,323,8,11,1,11,1,11,5,11,327,8,11,
10,11,12,11,330,9,11,3,11,332,8,11,1,11,1,11,1,11,1,11,1,11,1,11,1,11,1,
11,1,11,1,12,1,12,1,12,5,12,346,8,12,10,12,12,12,349,9,12,3,12,351,8,12,
1,12,3,12,354,8,12,1,12,1,12,5,12,358,8,12,10,12,12,12,361,9,12,3,12,363,
8,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,13,3,13,376,8,13,
1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,3,13,387,8,13,1,13,1,13,1,13,
1,13,1,13,1,13,3,13,395,8,13,1,13,1,13,1,13,1,13,1,14,3,14,402,8,14,1,14,
1,14,1,14,1,14,3,14,408,8,14,1,14,1,14,1,14,1,15,3,15,414,8,15,1,15,3,15,
417,8,15,1,15,1,15,1,15,1,15,1,15,1,15,1,16,1,16,1,16,1,16,1,16,5,16,430,
8,16,10,16,12,16,433,9,16,1,16,1,16,1,16,1,16,3,16,439,8,16,1,16,1,16,5,
16,443,8,16,10,16,12,16,446,9,16,1,16,1,16,1,17,1,17,1,17,1,18,1,18,3,18,
455,8,18,1,18,1,18,3,18,459,8,18,1,18,3,18,462,8,18,1,18,3,18,465,8,18,1,
19,1,19,1,19,5,19,470,8,19,10,19,12,19,473,9,19,1,19,1,19,1,19,3,19,478,
8,19,1,19,1,19,1,19,3,19,483,8,19,1,19,1,19,1,20,1,20,1,20,4,20,490,8,20,
11,20,12,20,491,1,20,1,20,1,21,1,21,1,21,1,21,3,21,500,8,21,1,22,1,22,1,
22,1,23,1,23,1,23,5,23,508,8,23,10,23,12,23,511,9,23,1,23,1,23,1,24,1,24,
1,24,1,24,1,24,1,24,1,24,1,25,1,25,3,25,524,8,25,1,26,1,26,3,26,528,8,26,
1,27,1,27,1,27,1,27,1,28,1,28,1,28,1,28,3,28,538,8,28,1,28,1,28,3,28,542,
8,28,1,28,1,28,1,28,1,28,1,28,5,28,549,8,28,10,28,12,28,552,9,28,3,28,554,
8,28,3,28,556,8,28,1,29,1,29,1,30,1,30,1,30,1,31,1,31,5,31,565,8,31,10,31,
12,31,568,9,31,1,31,1,31,1,32,1,32,3,32,574,8,32,1,33,1,33,1,33,3,33,579,
8,33,1,34,1,34,1,35,1,35,1,35,1,36,1,36,1,36,1,36,1,36,1,36,1,36,1,36,1,
36,3,36,595,8,36,1,37,1,37,1,37,1,38,1,38,1,38,1,38,1,38,1,38,1,38,1,38,
1,38,1,38,1,38,1,38,3,38,612,8,38,1,39,1,39,1,39,1,39,1,39,5,39,619,8,39,
10,39,12,39,622,9,39,1,39,1,39,3,39,626,8,39,1,40,1,40,1,41,1,41,3,41,632,
8,41,1,42,1,42,1,42,3,42,637,8,42,1,42,1,42,3,42,641,8,42,1,43,1,43,1,43,
1,44,1,44,1,45,1,45,1,45,1,45,1,45,1,45,1,45,3,45,655,8,45,1,46,1,46,1,46,
1,46,1,46,1,46,1,46,1,46,1,47,1,47,1,47,1,48,1,48,1,48,3,48,671,8,48,1,48,
5,48,674,8,48,10,48,12,48,677,9,48,1,48,1,48,1,48,3,48,682,8,48,1,49,1,49,
1,49,1,50,1,50,1,50,1,50,1,50,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,51,1,
51,1,51,3,51,702,8,51,1,52,1,52,1,52,1,52,1,52,1,52,3,52,710,8,52,1,52,1,
52,1,52,1,52,1,52,1,52,1,52,1,52,1,52,1,52,1,52,1,52,5,52,724,8,52,10,52,
12,52,727,9,52,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,
53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,3,53,748,8,53,1,53,1,53,1,53,1,53,
1,53,1,53,5,53,756,8,53,10,53,12,53,759,9,53,3,53,761,8,53,1,53,1,53,1,53,
3,53,766,8,53,1,53,1,53,5,53,770,8,53,10,53,12,53,773,9,53,1,53,1,53,3,53,
777,8,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,
1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,
53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,
1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,
53,1,53,3,53,837,8,53,1,53,1,53,1,53,1,53,5,53,843,8,53,10,53,12,53,846,
9,53,1,54,1,54,1,54,1,54,1,55,1,55,1,55,1,55,1,56,1,56,1,56,1,56,1,57,1,
57,1,57,1,57,1,58,1,58,1,58,1,58,1,59,1,59,1,59,1,59,1,60,1,60,1,60,3,60,
875,8,60,1,60,1,60,3,60,879,8,60,1,61,1,61,1,61,5,61,884,8,61,10,61,12,61,
887,9,61,1,62,1,62,1,62,5,62,892,8,62,10,62,12,62,895,9,62,1,63,1,63,1,64,
1,64,1,64,1,64,1,65,1,65,1,65,5,65,906,8,65,10,65,12,65,909,9,65,3,65,911,
8,65,1,66,1,66,1,66,1,66,1,66,3,66,918,8,66,1,67,1,67,1,67,1,67,1,67,3,67,
925,8,67,1,67,1,67,1,67,1,67,1,67,3,67,932,8,67,1,67,1,67,3,67,936,8,67,
3,67,938,8,67,3,67,940,8,67,1,68,1,68,1,69,1,69,1,70,1,70,1,71,1,71,1,72,
1,72,1,73,1,73,1,74,1,74,1,75,1,75,1,76,1,76,1,77,1,77,1,78,1,78,1,79,1,
79,1,80,1,80,1,81,1,81,1,82,1,82,1,83,1,83,1,84,1,84,1,85,1,85,1,85,1,86,
1,86,1,86,1,86,1,86,1,86,5,86,985,8,86,10,86,12,86,988,9,86,1,87,1,87,3,
87,992,8,87,1,88,1,88,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,
5,89,1006,8,89,10,89,12,89,1009,9,89,1,89,1,89,1,89,3,89,1014,8,89,1,89,
1,89,1,89,5,89,1019,8,89,10,89,12,89,1022,9,89,1,89,1,89,1,89,3,89,1027,
8,89,1,89,1,89,5,89,1031,8,89,10,89,12,89,1034,9,89,1,89,1,89,1,89,3,89,
1039,8,89,1,89,1,89,5,89,1043,8,89,10,89,12,89,1046,9,89,1,89,1,89,1,89,
3,89,1051,8,89,1,89,1,89,3,89,1055,8,89,1,89,1,89,5,89,1059,8,89,10,89,12,
89,1062,9,89,1,90,1,90,1,90,1,90,1,90,3,90,1069,8,90,1,91,1,91,3,91,1073,
8,91,1,91,1,91,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,93,1,93,1,93,1,94,1,
94,1,94,0,3,104,106,178,95,0,2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,
34,36,38,40,42,44,46,48,50,52,54,56,58,60,62,64,66,68,70,72,74,76,78,80,
82,84,86,88,90,92,94,96,98,100,102,104,106,108,110,112,114,116,118,120,122,
124,126,128,130,132,134,136,138,140,142,144,146,148,150,152,154,156,158,
160,162,164,166,168,170,172,174,176,178,180,182,184,186,188,0,7,2,0,3,4,
105,105,1,0,5,7,1,0,3,4,2,0,82,83,100,101,1,0,98,99,1,0,71,72,1,0,91,92,
1156,0,191,1,0,0,0,2,196,1,0,0,0,4,199,1,0,0,0,6,205,1,0,0,0,8,233,1,0,0,
0,10,252,1,0,0,0,12,254,1,0,0,0,14,267,1,0,0,0,16,276,1,0,0,0,18,286,1,0,
0,0,20,294,1,0,0,0,22,311,1,0,0,0,24,342,1,0,0,0,26,394,1,0,0,0,28,401,1,
0,0,0,30,413,1,0,0,0,32,424,1,0,0,0,34,449,1,0,0,0,36,452,1,0,0,0,38,466,
1,0,0,0,40,489,1,0,0,0,42,495,1,0,0,0,44,501,1,0,0,0,46,504,1,0,0,0,48,514,
1,0,0,0,50,523,1,0,0,0,52,527,1,0,0,0,54,529,1,0,0,0,56,533,1,0,0,0,58,557,
1,0,0,0,60,559,1,0,0,0,62,562,1,0,0,0,64,573,1,0,0,0,66,578,1,0,0,0,68,580,
1,0,0,0,70,582,1,0,0,0,72,594,1,0,0,0,74,596,1,0,0,0,76,611,1,0,0,0,78,625,
1,0,0,0,80,627,1,0,0,0,82,629,1,0,0,0,84,633,1,0,0,0,86,642,1,0,0,0,88,645,
1,0,0,0,90,647,1,0,0,0,92,656,1,0,0,0,94,664,1,0,0,0,96,681,1,0,0,0,98,683,
1,0,0,0,100,686,1,0,0,0,102,701,1,0,0,0,104,709,1,0,0,0,106,776,1,0,0,0,
108,847,1,0,0,0,110,851,1,0,0,0,112,855,1,0,0,0,114,859,1,0,0,0,116,863,
1,0,0,0,118,867,1,0,0,0,120,878,1,0,0,0,122,880,1,0,0,0,124,888,1,0,0,0,
126,896,1,0,0,0,128,898,1,0,0,0,130,910,1,0,0,0,132,912,1,0,0,0,134,939,
1,0,0,0,136,941,1,0,0,0,138,943,1,0,0,0,140,945,1,0,0,0,142,947,1,0,0,0,
144,949,1,0,0,0,146,951,1,0,0,0,148,953,1,0,0,0,150,955,1,0,0,0,152,957,
1,0,0,0,154,959,1,0,0,0,156,961,1,0,0,0,158,963,1,0,0,0,160,965,1,0,0,0,
162,967,1,0,0,0,164,969,1,0,0,0,166,971,1,0,0,0,168,973,1,0,0,0,170,975,
1,0,0,0,172,978,1,0,0,0,174,991,1,0,0,0,176,993,1,0,0,0,178,1050,1,0,0,0,
180,1068,1,0,0,0,182,1070,1,0,0,0,184,1076,1,0,0,0,186,1083,1,0,0,0,188,
1086,1,0,0,0,190,192,5,41,0,0,191,190,1,0,0,0,191,192,1,0,0,0,192,193,1,
0,0,0,193,194,3,6,3,0,194,195,5,0,0,1,195,1,1,0,0,0,196,197,3,134,67,0,197,
198,5,0,0,1,198,3,1,0,0,0,199,200,3,106,53,0,200,201,5,0,0,1,201,5,1,0,0,
0,202,204,3,18,9,0,203,202,1,0,0,0,204,207,1,0,0,0,205,203,1,0,0,0,205,206,
1,0,0,0,206,211,1,0,0,0,207,205,1,0,0,0,208,210,3,20,10,0,209,208,1,0,0,
0,210,213,1,0,0,0,211,209,1,0,0,0,211,212,1,0,0,0,212,216,1,0,0,0,213,211,
1,0,0,0,214,215,5,18,0,0,215,217,3,12,6,0,216,214,1,0,0,0,216,217,1,0,0,
0,217,220,1,0,0,0,218,219,5,59,0,0,219,221,3,8,4,0,220,218,1,0,0,0,220,221,
1,0,0,0,221,230,1,0,0,0,222,229,3,16,8,0,223,229,3,20,10,0,224,229,3,28,
14,0,225,229,3,26,13,0,226,229,3,30,15,0,227,229,3,24,12,0,228,222,1,0,0,
0,228,223,1,0,0,0,228,224,1,0,0,0,228,225,1,0,0,0,228,226,1,0,0,0,228,227,
1,0,0,0,229,232,1,0,0,0,230,228,1,0,0,0,230,231,1,0,0,0,231,7,1,0,0,0,232,
230,1,0,0,0,233,239,5,87,0,0,234,235,3,10,5,0,235,236,3,176,88,0,236,238,
1,0,0,0,237,234,1,0,0,0,238,241,1,0,0,0,239,237,1,0,0,0,239,240,1,0,0,0,
240,242,1,0,0,0,241,239,1,0,0,0,242,243,5,86,0,0,243,9,1,0,0,0,244,245,3,
154,77,0,245,246,5,90,0,0,246,247,3,106,53,0,247,253,1,0,0,0,248,249,3,154,
77,0,249,250,5,95,0,0,250,251,3,106,53,0,251,253,1,0,0,0,252,244,1,0,0,0,
252,248,1,0,0,0,253,11,1,0,0,0,254,260,5,87,0,0,255,256,3,14,7,0,256,257,
3,176,88,0,257,259,1,0,0,0,258,255,1,0,0,0,259,262,1,0,0,0,260,258,1,0,0,
0,260,261,1,0,0,0,261,263,1,0,0,0,262,260,1,0,0,0,263,264,5,86,0,0,264,13,
1,0,0,0,265,268,5,39,0,0,266,268,5,49,0,0,267,265,1,0,0,0,267,266,1,0,0,
0,267,268,1,0,0,0,268,269,1,0,0,0,269,270,3,154,77,0,270,271,5,90,0,0,271,
274,3,88,44,0,272,273,5,95,0,0,273,275,3,106,53,0,274,272,1,0,0,0,274,275,
1,0,0,0,275,15,1,0,0,0,276,277,5,29,0,0,277,278,5,33,0,0,278,279,3,164,82,
0,279,280,5,85,0,0,280,281,3,130,65,0,281,282,5,84,0,0,282,283,5,90,0,0,
283,284,3,88,44,0,284,285,5,91,0,0,285,17,1,0,0,0,286,287,5,37,0,0,287,290,
5,2,0,0,288,289,5,1,0,0,289,291,5,2,0,0,290,288,1,0,0,0,290,291,1,0,0,0,
291,292,1,0,0,0,292,293,5,91,0,0,293,19,1,0,0,0,294,295,5,64,0,0,295,296,
3,188,94,0,296,297,5,95,0,0,297,307,3,88,44,0,298,299,5,9,0,0,299,303,5,
87,0,0,300,302,3,22,11,0,301,300,1,0,0,0,302,305,1,0,0,0,303,301,1,0,0,0,
303,304,1,0,0,0,304,306,1,0,0,0,305,303,1,0,0,0,306,308,5,86,0,0,307,298,
1,0,0,0,307,308,1,0,0,0,308,309,1,0,0,0,309,310,5,91,0,0,310,21,1,0,0,0,
311,319,6,11,-1,0,312,315,5,107,0,0,313,315,5,109,0,0,314,312,1,0,0,0,314,
313,1,0,0,0,315,318,1,0,0,0,316,314,1,0,0,0,316,317,1,0,0,0,317,320,1,0,
0,0,318,316,1,0,0,0,319,316,1,0,0,0,319,320,1,0,0,0,320,322,1,0,0,0,321,
323,5,108,0,0,322,321,1,0,0,0,322,323,1,0,0,0,323,331,1,0,0,0,324,327,5,
107,0,0,325,327,5,109,0,0,326,324,1,0,0,0,326,325,1,0,0,0,327,330,1,0,0,
0,328,326,1,0,0,0,328,329,1,0,0,0,329,332,1,0,0,0,330,328,1,0,0,0,331,328,
1,0,0,0,331,332,1,0,0,0,332,333,1,0,0,0,333,334,6,11,-1,0,334,335,3,166,
83,0,335,336,5,85,0,0,336,337,3,130,65,0,337,338,5,84,0,0,338,339,5,90,0,
0,339,340,3,88,44,0,340,341,3,62,31,0,341,23,1,0,0,0,342,350,6,12,-1,0,343,
346,5,107,0,0,344,346,5,109,0,0,345,343,1,0,0,0,345,344,1,0,0,0,346,349,
1,0,0,0,347,345,1,0,0,0,347,348,1,0,0,0,348,351,1,0,0,0,349,347,1,0,0,0,
350,347,1,0,0,0,350,351,1,0,0,0,351,353,1,0,0,0,352,354,5,108,0,0,353,352,
1,0,0,0,353,354,1,0,0,0,354,362,1,0,0,0,355,358,5,107,0,0,356,358,5,109,
0,0,357,355,1,0,0,0,357,356,1,0,0,0,358,361,1,0,0,0,359,357,1,0,0,0,359,
360,1,0,0,0,360,363,1,0,0,0,361,359,1,0,0,0,362,359,1,0,0,0,362,363,1,0,
0,0,363,364,1,0,0,0,364,365,6,12,-1,0,365,366,5,33,0,0,366,367,3,166,83,
0,367,368,5,85,0,0,368,369,3,130,65,0,369,370,5,84,0,0,370,371,5,90,0,0,
371,372,3,88,44,0,372,373,3,62,31,0,373,25,1,0,0,0,374,376,5,58,0,0,375,
374,1,0,0,0,375,376,1,0,0,0,376,377,1,0,0,0,377,378,5,12,0,0,378,379,3,138,
69,0,379,380,5,85,0,0,380,381,3,130,65,0,381,382,5,84,0,0,382,383,5,90,0,
0,383,384,3,88,44,0,384,395,1,0,0,0,385,387,5,11,0,0,386,385,1,0,0,0,386,
387,1,0,0,0,387,388,1,0,0,0,388,389,5,12,0,0,389,390,3,138,69,0,390,391,
5,85,0,0,391,392,3,130,65,0,392,393,5,84,0,0,393,395,1,0,0,0,394,375,1,0,
0,0,394,386,1,0,0,0,395,396,1,0,0,0,396,397,5,87,0,0,397,398,3,6,3,0,398,
399,5,86,0,0,399,27,1,0,0,0,400,402,5,56,0,0,401,400,1,0,0,0,401,402,1,0,
0,0,402,403,1,0,0,0,403,404,5,43,0,0,404,405,3,136,68,0,405,407,5,87,0,0,
406,408,3,34,17,0,407,406,1,0,0,0,407,408,1,0,0,0,408,409,1,0,0,0,409,410,
3,36,18,0,410,411,5,86,0,0,411,29,1,0,0,0,412,414,5,34,0,0,413,412,1,0,0,
0,413,414,1,0,0,0,414,416,1,0,0,0,415,417,5,50,0,0,416,415,1,0,0,0,416,417,
1,0,0,0,417,418,1,0,0,0,418,419,5,22,0,0,419,420,3,140,70,0,420,421,5,87,
0,0,421,422,3,32,16,0,422,423,5,86,0,0,423,31,1,0,0,0,424,425,5,16,0,0,425,
431,5,87,0,0,426,427,3,52,26,0,427,428,5,91,0,0,428,430,1,0,0,0,429,426,
1,0,0,0,430,433,1,0,0,0,431,429,1,0,0,0,431,432,1,0,0,0,432,434,1,0,0,0,
433,431,1,0,0,0,434,444,5,86,0,0,435,436,5,55,0,0,436,439,3,84,42,0,437,
439,3,84,42,0,438,435,1,0,0,0,438,437,1,0,0,0,439,440,1,0,0,0,440,441,5,
91,0,0,441,443,1,0,0,0,442,438,1,0,0,0,443,446,1,0,0,0,444,442,1,0,0,0,444,
445,1,0,0,0,445,447,1,0,0,0,446,444,1,0,0,0,447,448,3,36,18,0,448,33,1,0,
0,0,449,450,5,15,0,0,450,451,5,2,0,0,451,35,1,0,0,0,452,454,3,60,30,0,453,
455,3,46,23,0,454,453,1,0,0,0,454,455,1,0,0,0,455,458,1,0,0,0,456,457,5,
50,0,0,457,459,3,60,30,0,458,456,1,0,0,0,458,459,1,0,0,0,459,461,1,0,0,0,
460,462,3,38,19,0,461,460,1,0,0,0,461,462,1,0,0,0,462,464,1,0,0,0,463,465,
3,44,22,0,464,463,1,0,0,0,464,465,1,0,0,0,465,37,1,0,0,0,466,467,5,48,0,
0,467,471,5,87,0,0,468,470,3,40,20,0,469,468,1,0,0,0,470,473,1,0,0,0,471,
469,1,0,0,0,471,472,1,0,0,0,472,477,1,0,0,0,473,471,1,0,0,0,474,475,5,20,
0,0,475,476,5,90,0,0,476,478,3,42,21,0,477,474,1,0,0,0,477,478,1,0,0,0,478,
482,1,0,0,0,479,480,5,22,0,0,480,481,5,90,0,0,481,483,3,42,21,0,482,479,
1,0,0,0,482,483,1,0,0,0,483,484,1,0,0,0,484,485,5,86,0,0,485,39,1,0,0,0,
486,487,3,142,71,0,487,488,5,90,0,0,488,490,1,0,0,0,489,486,1,0,0,0,490,
491,1,0,0,0,491,489,1,0,0,0,491,492,1,0,0,0,492,493,1,0,0,0,493,494,3,42,
21,0,494,41,1,0,0,0,495,499,3,60,30,0,496,497,5,46,0,0,497,498,5,17,0,0,
498,500,3,60,30,0,499,496,1,0,0,0,499,500,1,0,0,0,500,43,1,0,0,0,501,502,
5,47,0,0,502,503,3,62,31,0,503,45,1,0,0,0,504,505,5,61,0,0,505,509,5,87,
0,0,506,508,3,48,24,0,507,506,1,0,0,0,508,511,1,0,0,0,509,507,1,0,0,0,509,
510,1,0,0,0,510,512,1,0,0,0,511,509,1,0,0,0,512,513,5,86,0,0,513,47,1,0,
0,0,514,515,3,142,71,0,515,516,5,90,0,0,516,517,5,35,0,0,517,518,3,136,68,
0,518,519,3,50,25,0,519,520,5,91,0,0,520,49,1,0,0,0,521,524,3,52,26,0,522,
524,3,58,29,0,523,521,1,0,0,0,523,522,1,0,0,0,524,51,1,0,0,0,525,528,3,56,
28,0,526,528,3,54,27,0,527,525,1,0,0,0,527,526,1,0,0,0,528,53,1,0,0,0,529,
530,5,46,0,0,530,531,5,60,0,0,531,532,3,168,84,0,532,55,1,0,0,0,533,534,
5,46,0,0,534,537,3,106,53,0,535,536,5,51,0,0,536,538,5,72,0,0,537,535,1,
0,0,0,537,538,1,0,0,0,538,541,1,0,0,0,539,540,5,67,0,0,540,542,5,17,0,0,
541,539,1,0,0,0,541,542,1,0,0,0,542,555,1,0,0,0,543,544,5,62,0,0,544,553,
5,90,0,0,545,550,3,148,74,0,546,547,5,92,0,0,547,549,3,148,74,0,548,546,
1,0,0,0,549,552,1,0,0,0,550,548,1,0,0,0,550,551,1,0,0,0,551,554,1,0,0,0,
552,550,1,0,0,0,553,545,1,0,0,0,553,554,1,0,0,0,554,556,1,0,0,0,555,543,
1,0,0,0,555,556,1,0,0,0,556,57,1,0,0,0,557,558,1,0,0,0,558,59,1,0,0,0,559,
560,5,25,0,0,560,561,3,62,31,0,561,61,1,0,0,0,562,566,5,87,0,0,563,565,3,
64,32,0,564,563,1,0,0,0,565,568,1,0,0,0,566,564,1,0,0,0,566,567,1,0,0,0,
567,569,1,0,0,0,568,566,1,0,0,0,569,570,5,86,0,0,570,63,1,0,0,0,571,574,
3,66,33,0,572,574,3,62,31,0,573,571,1,0,0,0,573,572,1,0,0,0,574,65,1,0,0,
0,575,579,3,70,35,0,576,579,3,90,45,0,577,579,3,68,34,0,578,575,1,0,0,0,
578,576,1,0,0,0,578,577,1,0,0,0,579,67,1,0,0,0,580,581,3,92,46,0,581,69,
1,0,0,0,582,583,3,72,36,0,583,584,5,91,0,0,584,71,1,0,0,0,585,595,3,94,47,
0,586,595,3,98,49,0,587,595,3,100,50,0,588,595,3,82,41,0,589,595,3,80,40,
0,590,595,3,102,51,0,591,595,3,84,42,0,592,595,3,74,37,0,593,595,3,106,53,
0,594,585,1,0,0,0,594,586,1,0,0,0,594,587,1,0,0,0,594,588,1,0,0,0,594,589,
1,0,0,0,594,590,1,0,0,0,594,591,1,0,0,0,594,592,1,0,0,0,594,593,1,0,0,0,
595,73,1,0,0,0,596,597,5,42,0,0,597,598,3,106,53,0,598,75,1,0,0,0,599,600,
5,22,0,0,600,601,5,28,0,0,601,612,3,78,39,0,602,603,5,22,0,0,603,604,5,24,
0,0,604,612,3,78,39,0,605,606,5,22,0,0,606,607,5,53,0,0,607,612,3,78,39,
0,608,609,5,22,0,0,609,610,5,8,0,0,610,612,3,106,53,0,611,599,1,0,0,0,611,
602,1,0,0,0,611,605,1,0,0,0,611,608,1,0,0,0,612,77,1,0,0,0,613,626,3,140,
70,0,614,615,5,87,0,0,615,620,3,140,70,0,616,617,5,92,0,0,617,619,3,140,
70,0,618,616,1,0,0,0,619,622,1,0,0,0,620,618,1,0,0,0,620,621,1,0,0,0,621,
623,1,0,0,0,622,620,1,0,0,0,623,624,5,86,0,0,624,626,1,0,0,0,625,613,1,0,
0,0,625,614,1,0,0,0,626,79,1,0,0,0,627,628,5,30,0,0,628,81,1,0,0,0,629,631,
5,52,0,0,630,632,3,106,53,0,631,630,1,0,0,0,631,632,1,0,0,0,632,83,1,0,0,
0,633,636,3,86,43,0,634,635,5,90,0,0,635,637,3,88,44,0,636,634,1,0,0,0,636,
637,1,0,0,0,637,640,1,0,0,0,638,639,5,95,0,0,639,641,3,106,53,0,640,638,
1,0,0,0,640,641,1,0,0,0,641,85,1,0,0,0,642,643,5,65,0,0,643,644,3,154,77,
0,644,87,1,0,0,0,645,646,3,172,86,0,646,89,1,0,0,0,647,648,5,36,0,0,648,
649,5,85,0,0,649,650,3,106,53,0,650,651,5,84,0,0,651,654,3,64,32,0,652,653,
5,27,0,0,653,655,3,64,32,0,654,652,1,0,0,0,654,655,1,0,0,0,655,91,1,0,0,
0,656,657,5,32,0,0,657,658,5,85,0,0,658,659,3,86,43,0,659,660,5,38,0,0,660,
661,3,106,53,0,661,662,5,84,0,0,662,663,3,64,32,0,663,93,1,0,0,0,664,665,
5,66,0,0,665,666,3,96,48,0,666,95,1,0,0,0,667,668,5,87,0,0,668,675,3,142,
71,0,669,671,5,92,0,0,670,669,1,0,0,0,670,671,1,0,0,0,671,672,1,0,0,0,672,
674,3,142,71,0,673,670,1,0,0,0,674,677,1,0,0,0,675,673,1,0,0,0,675,676,1,
0,0,0,676,678,1,0,0,0,677,675,1,0,0,0,678,679,5,86,0,0,679,682,1,0,0,0,680,
682,5,5,0,0,681,667,1,0,0,0,681,680,1,0,0,0,682,97,1,0,0,0,683,684,5,35,
0,0,684,685,3,142,71,0,685,99,1,0,0,0,686,687,5,54,0,0,687,688,3,104,52,
0,688,689,5,95,0,0,689,690,3,106,53,0,690,101,1,0,0,0,691,692,5,54,0,0,692,
693,3,104,52,0,693,694,5,96,0,0,694,695,3,106,53,0,695,702,1,0,0,0,696,697,
5,54,0,0,697,698,3,104,52,0,698,699,5,97,0,0,699,700,3,106,53,0,700,702,
1,0,0,0,701,691,1,0,0,0,701,696,1,0,0,0,702,103,1,0,0,0,703,704,6,52,-1,
0,704,705,5,85,0,0,705,706,3,104,52,0,706,707,5,84,0,0,707,710,1,0,0,0,708,
710,3,134,67,0,709,703,1,0,0,0,709,708,1,0,0,0,710,725,1,0,0,0,711,712,10,
3,0,0,712,713,5,94,0,0,713,724,3,146,73,0,714,715,10,2,0,0,715,716,5,81,
0,0,716,717,5,72,0,0,717,724,5,80,0,0,718,719,10,1,0,0,719,720,5,81,0,0,
720,721,3,106,53,0,721,722,5,80,0,0,722,724,1,0,0,0,723,711,1,0,0,0,723,
714,1,0,0,0,723,718,1,0,0,0,724,727,1,0,0,0,725,723,1,0,0,0,725,726,1,0,
0,0,726,105,1,0,0,0,727,725,1,0,0,0,728,729,6,53,-1,0,729,777,3,110,55,0,
730,777,3,116,58,0,731,777,3,112,56,0,732,777,5,73,0,0,733,777,3,114,57,
0,734,777,3,168,84,0,735,777,5,2,0,0,736,737,5,85,0,0,737,738,3,106,53,0,
738,739,5,84,0,0,739,777,1,0,0,0,740,777,3,134,67,0,741,777,3,76,38,0,742,
743,7,0,0,0,743,777,3,106,53,15,744,745,3,134,67,0,745,747,5,40,0,0,746,
748,5,44,0,0,747,746,1,0,0,0,747,748,1,0,0,0,748,749,1,0,0,0,749,750,5,21,
0,0,750,777,1,0,0,0,751,760,5,87,0,0,752,757,3,108,54,0,753,754,5,92,0,0,
754,756,3,108,54,0,755,753,1,0,0,0,756,759,1,0,0,0,757,755,1,0,0,0,757,758,
1,0,0,0,758,761,1,0,0,0,759,757,1,0,0,0,760,752,1,0,0,0,760,761,1,0,0,0,
761,762,1,0,0,0,762,777,5,86,0,0,763,765,5,81,0,0,764,766,3,106,53,0,765,
764,1,0,0,0,765,766,1,0,0,0,766,771,1,0,0,0,767,768,5,92,0,0,768,770,3,106,
53,0,769,767,1,0,0,0,770,773,1,0,0,0,771,769,1,0,0,0,771,772,1,0,0,0,772,
774,1,0,0,0,773,771,1,0,0,0,774,777,5,80,0,0,775,777,5,79,0,0,776,728,1,
0,0,0,776,730,1,0,0,0,776,731,1,0,0,0,776,732,1,0,0,0,776,733,1,0,0,0,776,
734,1,0,0,0,776,735,1,0,0,0,776,736,1,0,0,0,776,740,1,0,0,0,776,741,1,0,
0,0,776,742,1,0,0,0,776,744,1,0,0,0,776,751,1,0,0,0,776,763,1,0,0,0,776,
775,1,0,0,0,777,844,1,0,0,0,778,779,10,12,0,0,779,780,7,1,0,0,780,843,3,
106,53,13,781,782,10,11,0,0,782,783,7,2,0,0,783,843,3,106,53,12,784,785,
10,10,0,0,785,786,7,3,0,0,786,843,3,106,53,11,787,788,10,9,0,0,788,789,7,
4,0,0,789,843,3,106,53,10,790,791,10,8,0,0,791,792,5,104,0,0,792,843,3,106,
53,9,793,794,10,7,0,0,794,795,5,102,0,0,795,843,3,106,53,8,796,797,10,6,
0,0,797,798,5,93,0,0,798,799,5,93,0,0,799,843,3,106,53,7,800,801,10,5,0,
0,801,802,5,93,0,0,802,803,3,106,53,0,803,804,5,90,0,0,804,805,3,106,53,
5,805,843,1,0,0,0,806,807,10,23,0,0,807,808,5,94,0,0,808,843,3,146,73,0,
809,810,10,22,0,0,810,811,5,93,0,0,811,812,5,94,0,0,812,843,3,146,73,0,813,
814,10,21,0,0,814,815,5,94,0,0,815,816,3,146,73,0,816,817,3,118,59,0,817,
843,1,0,0,0,818,819,10,20,0,0,819,820,5,93,0,0,820,821,5,94,0,0,821,822,
3,146,73,0,822,823,3,118,59,0,823,843,1,0,0,0,824,825,10,19,0,0,825,826,
5,81,0,0,826,827,5,72,0,0,827,843,5,80,0,0,828,829,10,18,0,0,829,830,5,81,
0,0,830,831,3,106,53,0,831,832,5,80,0,0,832,843,1,0,0,0,833,834,10,14,0,
0,834,836,5,40,0,0,835,837,5,44,0,0,836,835,1,0,0,0,836,837,1,0,0,0,837,
838,1,0,0,0,838,843,5,79,0,0,839,840,10,1,0,0,840,841,5,10,0,0,841,843,3,
88,44,0,842,778,1,0,0,0,842,781,1,0,0,0,842,784,1,0,0,0,842,787,1,0,0,0,
842,790,1,0,0,0,842,793,1,0,0,0,842,796,1,0,0,0,842,800,1,0,0,0,842,806,
1,0,0,0,842,809,1,0,0,0,842,813,1,0,0,0,842,818,1,0,0,0,842,824,1,0,0,0,
842,828,1,0,0,0,842,833,1,0,0,0,842,839,1,0,0,0,843,846,1,0,0,0,844,842,
1,0,0,0,844,845,1,0,0,0,845,107,1,0,0,0,846,844,1,0,0,0,847,848,3,160,80,
0,848,849,5,90,0,0,849,850,3,106,53,0,850,109,1,0,0,0,851,852,5,29,0,0,852,
853,3,164,82,0,853,854,3,118,59,0,854,111,1,0,0,0,855,856,5,13,0,0,856,857,
3,138,69,0,857,858,3,118,59,0,858,113,1,0,0,0,859,860,5,68,0,0,860,861,3,
188,94,0,861,862,3,118,59,0,862,115,1,0,0,0,863,864,5,69,0,0,864,865,3,162,
81,0,865,866,3,118,59,0,866,117,1,0,0,0,867,868,5,85,0,0,868,869,3,120,60,
0,869,870,5,84,0,0,870,119,1,0,0,0,871,874,3,124,62,0,872,873,5,92,0,0,873,
875,3,122,61,0,874,872,1,0,0,0,874,875,1,0,0,0,875,879,1,0,0,0,876,879,3,
122,61,0,877,879,1,0,0,0,878,871,1,0,0,0,878,876,1,0,0,0,878,877,1,0,0,0,
879,121,1,0,0,0,880,885,3,128,64,0,881,882,5,92,0,0,882,884,3,128,64,0,883,
881,1,0,0,0,884,887,1,0,0,0,885,883,1,0,0,0,885,886,1,0,0,0,886,123,1,0,
0,0,887,885,1,0,0,0,888,893,3,126,63,0,889,890,5,92,0,0,890,892,3,126,63,
0,891,889,1,0,0,0,892,895,1,0,0,0,893,891,1,0,0,0,893,894,1,0,0,0,894,125,
1,0,0,0,895,893,1,0,0,0,896,897,3,106,53,0,897,127,1,0,0,0,898,899,3,156,
78,0,899,900,5,90,0,0,900,901,3,106,53,0,901,129,1,0,0,0,902,907,3,132,66,
0,903,904,5,92,0,0,904,906,3,132,66,0,905,903,1,0,0,0,906,909,1,0,0,0,907,
905,1,0,0,0,907,908,1,0,0,0,908,911,1,0,0,0,909,907,1,0,0,0,910,902,1,0,
0,0,910,911,1,0,0,0,911,131,1,0,0,0,912,913,3,158,79,0,913,914,5,90,0,0,
914,917,3,88,44,0,915,916,5,95,0,0,916,918,3,106,53,0,917,915,1,0,0,0,917,
918,1,0,0,0,918,133,1,0,0,0,919,920,5,69,0,0,920,940,3,144,72,0,921,940,
3,144,72,0,922,924,5,70,0,0,923,925,3,144,72,0,924,923,1,0,0,0,924,925,1,
0,0,0,925,940,1,0,0,0,926,937,5,22,0,0,927,928,5,94,0,0,928,931,3,140,70,
0,929,930,5,94,0,0,930,932,5,55,0,0,931,929,1,0,0,0,931,932,1,0,0,0,932,
935,1,0,0,0,933,934,5,94,0,0,934,936,3,144,72,0,935,933,1,0,0,0,935,936,
1,0,0,0,936,938,1,0,0,0,937,927,1,0,0,0,937,938,1,0,0,0,938,940,1,0,0,0,
939,919,1,0,0,0,939,921,1,0,0,0,939,922,1,0,0,0,939,926,1,0,0,0,940,135,
1,0,0,0,941,942,5,106,0,0,942,137,1,0,0,0,943,944,5,106,0,0,944,139,1,0,
0,0,945,946,5,106,0,0,946,141,1,0,0,0,947,948,5,106,0,0,948,143,1,0,0,0,
949,950,5,106,0,0,950,145,1,0,0,0,951,952,5,106,0,0,952,147,1,0,0,0,953,
954,5,106,0,0,954,149,1,0,0,0,955,956,5,106,0,0,956,151,1,0,0,0,957,958,
5,106,0,0,958,153,1,0,0,0,959,960,5,106,0,0,960,155,1,0,0,0,961,962,5,106,
0,0,962,157,1,0,0,0,963,964,5,106,0,0,964,159,1,0,0,0,965,966,5,106,0,0,
966,161,1,0,0,0,967,968,5,106,0,0,968,163,1,0,0,0,969,970,5,106,0,0,970,
165,1,0,0,0,971,972,5,106,0,0,972,167,1,0,0,0,973,974,7,5,0,0,974,169,1,
0,0,0,975,976,3,172,86,0,976,977,5,0,0,1,977,171,1,0,0,0,978,979,3,178,89,
0,979,986,3,174,87,0,980,981,5,103,0,0,981,982,3,178,89,0,982,983,3,174,
87,0,983,985,1,0,0,0,984,980,1,0,0,0,985,988,1,0,0,0,986,984,1,0,0,0,986,
987,1,0,0,0,987,173,1,0,0,0,988,986,1,0,0,0,989,992,5,93,0,0,990,992,1,0,
0,0,991,989,1,0,0,0,991,990,1,0,0,0,992,175,1,0,0,0,993,994,7,6,0,0,994,
177,1,0,0,0,995,996,6,89,-1,0,996,997,5,85,0,0,997,998,3,172,86,0,998,999,
5,84,0,0,999,1051,1,0,0,0,1000,1051,3,180,90,0,1001,1007,5,87,0,0,1002,1003,
3,182,91,0,1003,1004,3,176,88,0,1004,1006,1,0,0,0,1005,1002,1,0,0,0,1006,
1009,1,0,0,0,1007,1005,1,0,0,0,1007,1008,1,0,0,0,1008,1013,1,0,0,0,1009,
1007,1,0,0,0,1010,1011,3,184,92,0,1011,1012,3,176,88,0,1012,1014,1,0,0,0,
1013,1010,1,0,0,0,1013,1014,1,0,0,0,1014,1020,1,0,0,0,1015,1016,3,182,91,
0,1016,1017,3,176,88,0,1017,1019,1,0,0,0,1018,1015,1,0,0,0,1019,1022,1,0,
0,0,1020,1018,1,0,0,0,1020,1021,1,0,0,0,1021,1023,1,0,0,0,1022,1020,1,0,
0,0,1023,1051,5,86,0,0,1024,1026,5,81,0,0,1025,1027,3,172,86,0,1026,1025,
1,0,0,0,1026,1027,1,0,0,0,1027,1032,1,0,0,0,1028,1029,5,92,0,0,1029,1031,
3,172,86,0,1030,1028,1,0,0,0,1031,1034,1,0,0,0,1032,1030,1,0,0,0,1032,1033,
1,0,0,0,1033,1035,1,0,0,0,1034,1032,1,0,0,0,1035,1051,5,80,0,0,1036,1038,
5,83,0,0,1037,1039,3,172,86,0,1038,1037,1,0,0,0,1038,1039,1,0,0,0,1039,1044,
1,0,0,0,1040,1041,5,103,0,0,1041,1043,3,172,86,0,1042,1040,1,0,0,0,1043,
1046,1,0,0,0,1044,1042,1,0,0,0,1044,1045,1,0,0,0,1045,1047,1,0,0,0,1046,
1044,1,0,0,0,1047,1051,5,82,0,0,1048,1051,5,2,0,0,1049,1051,3,188,94,0,1050,
995,1,0,0,0,1050,1000,1,0,0,0,1050,1001,1,0,0,0,1050,1024,1,0,0,0,1050,1036,
1,0,0,0,1050,1048,1,0,0,0,1050,1049,1,0,0,0,1051,1060,1,0,0,0,1052,1054,
10,5,0,0,1053,1055,5,93,0,0,1054,1053,1,0,0,0,1054,1055,1,0,0,0,1055,1056,
1,0,0,0,1056,1057,5,81,0,0,1057,1059,5,80,0,0,1058,1052,1,0,0,0,1059,1062,
1,0,0,0,1060,1058,1,0,0,0,1060,1061,1,0,0,0,1061,179,1,0,0,0,1062,1060,1,
0,0,0,1063,1069,5,78,0,0,1064,1069,5,75,0,0,1065,1069,5,76,0,0,1066,1069,
5,77,0,0,1067,1069,5,74,0,0,1068,1063,1,0,0,0,1068,1064,1,0,0,0,1068,1065,
1,0,0,0,1068,1066,1,0,0,0,1068,1067,1,0,0,0,1069,181,1,0,0,0,1070,1072,3,
160,80,0,1071,1073,5,93,0,0,1072,1071,1,0,0,0,1072,1073,1,0,0,0,1073,1074,
1,0,0,0,1074,1075,3,186,93,0,1075,183,1,0,0,0,1076,1077,5,81,0,0,1077,1078,
5,106,0,0,1078,1079,5,90,0,0,1079,1080,5,77,0,0,1080,1081,5,80,0,0,1081,
1082,3,186,93,0,1082,185,1,0,0,0,1083,1084,5,90,0,0,1084,1085,3,172,86,0,
1085,187,1,0,0,0,1086,1087,5,106,0,0,1087,189,1,0,0,0,109,191,205,211,216,
220,228,230,239,252,260,267,274,290,303,307,314,316,319,322,326,328,331,
345,347,350,353,357,359,362,375,386,394,401,407,413,416,431,438,444,454,
458,461,464,471,477,482,491,499,509,523,527,537,541,550,553,555,566,573,
578,594,611,620,625,631,636,640,654,670,675,681,701,709,723,725,747,757,
760,765,771,776,836,842,844,874,878,885,893,907,910,917,924,931,935,937,
939,986,991,1007,1013,1020,1026,1032,1038,1044,1050,1054,1060,1068,1072];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.atn.PredictionContextCache();

export default class graphParser extends antlr4.Parser {

    static grammarFileName = "graph.g4";
    static literalNames = [ null, "'from'", null, "'+'", "'-'", "'*'", "'/'", 
                            "'%'", "'apply'", "'with'", "'as'", "'async'", 
                            "'block'", "'blockcall'", "'catch'", "'comment'", 
                            "'conditions'", "'confident'", "'context'", 
                            "'cow'", "'default'", "'defined'", "'digression'", 
                            "'digressions'", "'disable'", "'do'", "'done'", 
                            "'else'", "'enable'", "'external'", "'exit'", 
                            "'finally'", "'for'", "'function'", "'global'", 
                            "'goto'", "'if'", "'import'", "'in'", "'input'", 
                            "'is'", "'library'", "'logexpr'", "'node'", 
                            "'not'", "'of'", "'on'", "'ondigreturn'", "'onexit'", 
                            "'output'", "'preprocessor'", "'priority'", 
                            "'return'", "'save'", "'set'", "'shared'", "'start'", 
                            "'static'", "'sync'", "'system'", "'timeout'", 
                            "'transitions'", "'tags'", "'try'", "'type'", 
                            "'var'", "'wait'", "'when'", "'fill'", "'#'", 
                            "'$'", null, null, null, "'empty'", "'number'", 
                            "'boolean'", "'string'", "'unknown'", "'null'", 
                            "']'", "'['", "'>'", "'<'", "')'", "'('", "'}'", 
                            "'{'", "'\"'", "'`'", "':'", "';'", "','", "'?'", 
                            "'.'", "'='", "'+='", "'-='", "'=='", "'!='", 
                            "'>='", "'<='", null, "'|'" ];
    static symbolicNames = [ null, null, "StringLiteral", "PLUS", "MINUS", 
                             "STAR", "SLASH", "PERCENT", "APPLY", "WITH", 
                             "AS", "ASYNC", "BLOCK", "BLOCKCALL", "CATCH", 
                             "COMMENT", "CONDITIONS", "CONFIDENT", "CONTEXT", 
                             "COW", "DEFAULT", "DEFINED", "DIGRESSION", 
                             "DIGRESSIONS", "DISABLE", "DO", "DONE", "ELSE", 
                             "ENABLE", "EXTERNAL", "EXIT", "FINALLY", "FOR", 
                             "FUNCTION", "GLOBAL", "GOTO", "IF", "IMPORT", 
                             "IN", "INPUT", "IS", "LIBRARY", "LOGEXPR", 
                             "NODE", "NOT", "OF", "ON", "ONDIGRETURN", "ONEXIT", 
                             "OUTPUT", "PREPROCESSOR", "PRIORITY", "RETURN", 
                             "SAVE", "SET", "SHARED", "START", "STATIC", 
                             "SYNC", "SYSTEM", "TIMEOUT", "TRANSITIONS", 
                             "TAGS", "TRY", "TYPE", "VAR", "WAIT", "WHEN", 
                             "FILL", "BuiltinMark", "ContextMark", "DecimalLiteral", 
                             "DecimalIntegerLiteral", "BooleanLiteral", 
                             "Empty", "Number", "Boolean", "String", "Unknown", 
                             "NULL", "RBRACKET", "LBRACKET", "RANGLE", "LANGLE", 
                             "RPAREN", "LPAREN", "RCURL", "LCURL", "QUOTE", 
                             "BACKTRICK", "COLON", "SEMICOLON", "COMMA", 
                             "QMARK", "PERIOD", "EQUALSIGN", "AddOp", "RemoveOp", 
                             "EqualOp", "NotEqualOp", "GreaterEqualOp", 
                             "LessEqualOp", "OrOp", "TypeOr", "AndOp", "NotOperator", 
                             "ID", "LineComment", "DocsComment", "BlockComment", 
                             "Whitespace" ];
    static ruleNames = [ "file", "referenceFile", "expressionFile", "blockContent", 
                         "systemDescription", "systemElementDescription", 
                         "contextDescription", "contextElementDescription", 
                         "externalFunctionDeclaration", "importDefinition", 
                         "typeDefinition", "functionDescription", "contextFunctionDescription", 
                         "blockDeclaration", "nodeDeclaration", "digressionDeclaration", 
                         "digressionContent", "comment", "nodeContent", 
                         "nodeOnExitSection", "nodeOnExitSectionItem", "onExitDoWithConfident", 
                         "nodeOnDigressionReturnSection", "nodeTransitionSection", 
                         "namedTransitionDefinition", "transitionDefinition", 
                         "transitionDefinitionOnMessage", "timeoutTransitionDefinition", 
                         "conditionalTransitionDefinition", "unconditionalTransitionDefinition", 
                         "doSection", "blockBody", "oneOrGroupedCommand", 
                         "nodeCommand", "cycleCommand", "statement", "statementBody", 
                         "logExpressionTypeCommand", "digressionControl", 
                         "digressionSpec", "exitCommand", "returnCommand", 
                         "variableDefinition", "variableDeclaration", "typeDescription", 
                         "conditionalCommand", "foreachCommand", "waitCommand", 
                         "activatedTransitionList", "gotoCommand", "setCommand", 
                         "assignOpCommand", "lValue", "expression", "objectPropertyExpressionPart", 
                         "externalFunctionCall", "blockFunctionCall", "slotFillingCall", 
                         "builtinFunctionCall", "argumentInvokation", "argumentList", 
                         "namedArgumentList", "positionalArgumentList", 
                         "positionalArgument", "namedArgument", "parameterList", 
                         "parameter", "reference", "nodeId", "blockId", 
                         "digressionId", "transitionName", "referenceName", 
                         "memberName", "tagName", "factName", "fieldName", 
                         "variableName", "argumentName", "parameterName", 
                         "propertyName", "builtinFunctionName", "externalFunctionName", 
                         "functionName", "anyNumber", "typeEof", "type_", 
                         "questionMarkOptional", "objectTypeElementSeparator", 
                         "nonNullableType", "predefinedType", "propertySignature", 
                         "indexSignature", "typeAnnotation", "typeAlias" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = graphParser.ruleNames;
        this.literalNames = graphParser.literalNames;
        this.symbolicNames = graphParser.symbolicNames;
    }

    sempred(localctx, ruleIndex, predIndex) {
    	switch(ruleIndex) {
    	case 52:
    	    		return this.lValue_sempred(localctx, predIndex);
    	case 53:
    	    		return this.expression_sempred(localctx, predIndex);
    	case 89:
    	    		return this.nonNullableType_sempred(localctx, predIndex);
        default:
            throw "No predicate with index:" + ruleIndex;
       }
    }

    lValue_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 0:
    			return this.precpred(this._ctx, 3);
    		case 1:
    			return this.precpred(this._ctx, 2);
    		case 2:
    			return this.precpred(this._ctx, 1);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };

    expression_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 3:
    			return this.precpred(this._ctx, 12);
    		case 4:
    			return this.precpred(this._ctx, 11);
    		case 5:
    			return this.precpred(this._ctx, 10);
    		case 6:
    			return this.precpred(this._ctx, 9);
    		case 7:
    			return this.precpred(this._ctx, 8);
    		case 8:
    			return this.precpred(this._ctx, 7);
    		case 9:
    			return this.precpred(this._ctx, 6);
    		case 10:
    			return this.precpred(this._ctx, 5);
    		case 11:
    			return this.precpred(this._ctx, 23);
    		case 12:
    			return this.precpred(this._ctx, 22);
    		case 13:
    			return this.precpred(this._ctx, 21);
    		case 14:
    			return this.precpred(this._ctx, 20);
    		case 15:
    			return this.precpred(this._ctx, 19);
    		case 16:
    			return this.precpred(this._ctx, 18);
    		case 17:
    			return this.precpred(this._ctx, 14);
    		case 18:
    			return this.precpred(this._ctx, 1);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };

    nonNullableType_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 19:
    			return this.precpred(this._ctx, 5);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };




	file() {
	    let localctx = new FileContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, graphParser.RULE_file);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 191;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===41) {
	            this.state = 190;
	            this.match(graphParser.LIBRARY);
	        }

	        this.state = 193;
	        localctx.mainBlock = this.blockContent();
	        this.state = 194;
	        this.match(graphParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	referenceFile() {
	    let localctx = new ReferenceFileContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, graphParser.RULE_referenceFile);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 196;
	        this.reference();
	        this.state = 197;
	        this.match(graphParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	expressionFile() {
	    let localctx = new ExpressionFileContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, graphParser.RULE_expressionFile);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 199;
	        this.expression(0);
	        this.state = 200;
	        this.match(graphParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockContent() {
	    let localctx = new BlockContentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, graphParser.RULE_blockContent);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 205;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===37) {
	            this.state = 202;
	            localctx._importDefinition = this.importDefinition();
	            localctx.imports.push(localctx._importDefinition);
	            this.state = 207;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 211;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,2,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 208;
	                localctx._typeDefinition = this.typeDefinition();
	                localctx.types.push(localctx._typeDefinition); 
	            }
	            this.state = 213;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,2,this._ctx);
	        }

	        this.state = 216;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===18) {
	            this.state = 214;
	            this.match(graphParser.CONTEXT);
	            this.state = 215;
	            localctx.contextType = this.contextDescription();
	        }

	        this.state = 220;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===59) {
	            this.state = 218;
	            this.match(graphParser.SYSTEM);
	            this.state = 219;
	            localctx.system = this.systemDescription();
	        }

	        this.state = 230;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while((((_la) & ~0x1f) === 0 && ((1 << _la) & 541071360) !== 0) || ((((_la - 33)) & ~0x1f) === 0 && ((1 << (_la - 33)) & 2189558787) !== 0) || ((((_la - 107)) & ~0x1f) === 0 && ((1 << (_la - 107)) & 7) !== 0)) {
	            this.state = 228;
	            this._errHandler.sync(this);
	            switch(this._input.LA(1)) {
	            case 29:
	                this.state = 222;
	                localctx._externalFunctionDeclaration = this.externalFunctionDeclaration();
	                localctx.externalFunctions.push(localctx._externalFunctionDeclaration);
	                break;
	            case 64:
	                this.state = 223;
	                localctx._typeDefinition = this.typeDefinition();
	                localctx.types.push(localctx._typeDefinition);
	                break;
	            case 43:
	            case 56:
	                this.state = 224;
	                localctx._nodeDeclaration = this.nodeDeclaration();
	                localctx.nodes.push(localctx._nodeDeclaration);
	                break;
	            case 11:
	            case 12:
	            case 58:
	                this.state = 225;
	                localctx._blockDeclaration = this.blockDeclaration();
	                localctx.blocks.push(localctx._blockDeclaration);
	                break;
	            case 22:
	            case 34:
	            case 50:
	                this.state = 226;
	                localctx._digressionDeclaration = this.digressionDeclaration();
	                localctx.digressions.push(localctx._digressionDeclaration);
	                break;
	            case 33:
	            case 107:
	            case 108:
	            case 109:
	                this.state = 227;
	                localctx._contextFunctionDescription = this.contextFunctionDescription();
	                localctx.contextFunctions.push(localctx._contextFunctionDescription);
	                break;
	            default:
	                throw new antlr4.error.NoViableAltException(this);
	            }
	            this.state = 232;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	systemDescription() {
	    let localctx = new SystemDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, graphParser.RULE_systemDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 233;
	        this.match(graphParser.LCURL);
	        this.state = 239;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===106) {
	            this.state = 234;
	            localctx._systemElementDescription = this.systemElementDescription();
	            localctx.elements.push(localctx._systemElementDescription);
	            this.state = 235;
	            this.objectTypeElementSeparator();
	            this.state = 241;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 242;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	systemElementDescription() {
	    let localctx = new SystemElementDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, graphParser.RULE_systemElementDescription);
	    try {
	        this.state = 252;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,8,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 244;
	            localctx.name = this.variableName();
	            this.state = 245;
	            this.match(graphParser.COLON);
	            this.state = 246;
	            localctx.value = this.expression(0);
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 248;
	            localctx.name = this.variableName();
	            this.state = 249;
	            this.match(graphParser.EQUALSIGN);
	            this.state = 250;
	            localctx.value = this.expression(0);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	contextDescription() {
	    let localctx = new ContextDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, graphParser.RULE_contextDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 254;
	        this.match(graphParser.LCURL);
	        this.state = 260;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===39 || _la===49 || _la===106) {
	            this.state = 255;
	            localctx._contextElementDescription = this.contextElementDescription();
	            localctx.elements.push(localctx._contextElementDescription);
	            this.state = 256;
	            this.objectTypeElementSeparator();
	            this.state = 262;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 263;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	contextElementDescription() {
	    let localctx = new ContextElementDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, graphParser.RULE_contextElementDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 267;
	        this._errHandler.sync(this);
	        switch (this._input.LA(1)) {
	        case 39:
	        	this.state = 265;
	        	localctx.input = this.match(graphParser.INPUT);
	        	break;
	        case 49:
	        	this.state = 266;
	        	localctx.output = this.match(graphParser.OUTPUT);
	        	break;
	        case 106:
	        	break;
	        default:
	        	break;
	        }
	        this.state = 269;
	        localctx.name = this.variableName();
	        this.state = 270;
	        this.match(graphParser.COLON);
	        this.state = 271;
	        localctx.type = this.typeDescription();
	        this.state = 274;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===95) {
	            this.state = 272;
	            this.match(graphParser.EQUALSIGN);
	            this.state = 273;
	            localctx.value = this.expression(0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	externalFunctionDeclaration() {
	    let localctx = new ExternalFunctionDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 16, graphParser.RULE_externalFunctionDeclaration);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 276;
	        this.match(graphParser.EXTERNAL);
	        this.state = 277;
	        this.match(graphParser.FUNCTION);
	        this.state = 278;
	        localctx.name = this.externalFunctionName();
	        this.state = 279;
	        this.match(graphParser.LPAREN);
	        this.state = 280;
	        localctx.parameters = this.parameterList();
	        this.state = 281;
	        this.match(graphParser.RPAREN);
	        this.state = 282;
	        this.match(graphParser.COLON);
	        this.state = 283;
	        localctx.returnType = this.typeDescription();
	        this.state = 284;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	importDefinition() {
	    let localctx = new ImportDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 18, graphParser.RULE_importDefinition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 286;
	        this.match(graphParser.IMPORT);
	        this.state = 287;
	        localctx.path = this.match(graphParser.StringLiteral);
	        this.state = 290;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===1) {
	            this.state = 288;
	            this.match(graphParser.T__0);
	            this.state = 289;
	            localctx.packageName = this.match(graphParser.StringLiteral);
	        }

	        this.state = 292;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeDefinition() {
	    let localctx = new TypeDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 20, graphParser.RULE_typeDefinition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 294;
	        this.match(graphParser.TYPE);
	        this.state = 295;
	        this.typeAlias();
	        this.state = 296;
	        this.match(graphParser.EQUALSIGN);
	        this.state = 297;
	        this.typeDescription();
	        this.state = 307;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===9) {
	            this.state = 298;
	            this.match(graphParser.WITH);
	            this.state = 299;
	            this.match(graphParser.LCURL);
	            this.state = 303;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(((((_la - 106)) & ~0x1f) === 0 && ((1 << (_la - 106)) & 15) !== 0)) {
	                this.state = 300;
	                localctx._functionDescription = this.functionDescription();
	                localctx.functions.push(localctx._functionDescription);
	                this.state = 305;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 306;
	            this.match(graphParser.RCURL);
	        }

	        this.state = 309;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	functionDescription() {
	    let localctx = new FunctionDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 22, graphParser.RULE_functionDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.enableChannel(2);
	        this.state = 319;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,17,this._ctx);
	        if(la_===1) {
	            this.state = 316;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,16,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 314;
	                    this._errHandler.sync(this);
	                    switch(this._input.LA(1)) {
	                    case 107:
	                        this.state = 312;
	                        localctx._LineComment = this.match(graphParser.LineComment);
	                        localctx.comments.push(localctx._LineComment);
	                        break;
	                    case 109:
	                        this.state = 313;
	                        this.match(graphParser.BlockComment);
	                        break;
	                    default:
	                        throw new antlr4.error.NoViableAltException(this);
	                    } 
	                }
	                this.state = 318;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,16,this._ctx);
	            }


	        }
	        this.state = 322;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===108) {
	            this.state = 321;
	            localctx.doc = this.match(graphParser.DocsComment);
	        }

	        this.state = 331;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,21,this._ctx);
	        if(la_===1) {
	            this.state = 328;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===107 || _la===109) {
	                this.state = 326;
	                this._errHandler.sync(this);
	                switch(this._input.LA(1)) {
	                case 107:
	                    this.state = 324;
	                    localctx._LineComment = this.match(graphParser.LineComment);
	                    localctx.comments.push(localctx._LineComment);
	                    break;
	                case 109:
	                    this.state = 325;
	                    this.match(graphParser.BlockComment);
	                    break;
	                default:
	                    throw new antlr4.error.NoViableAltException(this);
	                }
	                this.state = 330;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }

	        }
	        this.disableChannel(2);
	        this.state = 334;
	        localctx.name = this.functionName();
	        this.state = 335;
	        this.match(graphParser.LPAREN);
	        this.state = 336;
	        localctx.parameters = this.parameterList();
	        this.state = 337;
	        this.match(graphParser.RPAREN);
	        this.state = 338;
	        this.match(graphParser.COLON);
	        this.state = 339;
	        localctx.returnType = this.typeDescription();
	        this.state = 340;
	        localctx.body = this.blockBody();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	contextFunctionDescription() {
	    let localctx = new ContextFunctionDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 24, graphParser.RULE_contextFunctionDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.enableChannel(2);
	        this.state = 350;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,24,this._ctx);
	        if(la_===1) {
	            this.state = 347;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,23,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 345;
	                    this._errHandler.sync(this);
	                    switch(this._input.LA(1)) {
	                    case 107:
	                        this.state = 343;
	                        localctx._LineComment = this.match(graphParser.LineComment);
	                        localctx.comments.push(localctx._LineComment);
	                        break;
	                    case 109:
	                        this.state = 344;
	                        this.match(graphParser.BlockComment);
	                        break;
	                    default:
	                        throw new antlr4.error.NoViableAltException(this);
	                    } 
	                }
	                this.state = 349;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,23,this._ctx);
	            }


	        }
	        this.state = 353;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===108) {
	            this.state = 352;
	            localctx.doc = this.match(graphParser.DocsComment);
	        }

	        this.state = 362;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,28,this._ctx);
	        if(la_===1) {
	            this.state = 359;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===107 || _la===109) {
	                this.state = 357;
	                this._errHandler.sync(this);
	                switch(this._input.LA(1)) {
	                case 107:
	                    this.state = 355;
	                    localctx._LineComment = this.match(graphParser.LineComment);
	                    localctx.comments.push(localctx._LineComment);
	                    break;
	                case 109:
	                    this.state = 356;
	                    this.match(graphParser.BlockComment);
	                    break;
	                default:
	                    throw new antlr4.error.NoViableAltException(this);
	                }
	                this.state = 361;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }

	        }
	        this.disableChannel(2);
	        this.state = 365;
	        this.match(graphParser.FUNCTION);
	        this.state = 366;
	        localctx.name = this.functionName();
	        this.state = 367;
	        this.match(graphParser.LPAREN);
	        this.state = 368;
	        localctx.parameters = this.parameterList();
	        this.state = 369;
	        this.match(graphParser.RPAREN);
	        this.state = 370;
	        this.match(graphParser.COLON);
	        this.state = 371;
	        localctx.returnType = this.typeDescription();
	        this.state = 372;
	        localctx.body = this.blockBody();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockDeclaration() {
	    let localctx = new BlockDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 26, graphParser.RULE_blockDeclaration);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 394;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,31,this._ctx);
	        switch(la_) {
	        case 1:
	            this.state = 375;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===58) {
	                this.state = 374;
	                localctx.sync = this.match(graphParser.SYNC);
	            }

	            this.state = 377;
	            this.match(graphParser.BLOCK);
	            this.state = 378;
	            this.blockId();
	            this.state = 379;
	            this.match(graphParser.LPAREN);
	            this.state = 380;
	            this.parameterList();
	            this.state = 381;
	            this.match(graphParser.RPAREN);
	            this.state = 382;
	            this.match(graphParser.COLON);
	            this.state = 383;
	            localctx.returnType = this.typeDescription();
	            break;

	        case 2:
	            this.state = 386;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===11) {
	                this.state = 385;
	                localctx.async = this.match(graphParser.ASYNC);
	            }

	            this.state = 388;
	            this.match(graphParser.BLOCK);
	            this.state = 389;
	            this.blockId();
	            this.state = 390;
	            this.match(graphParser.LPAREN);
	            this.state = 391;
	            this.parameterList();
	            this.state = 392;
	            this.match(graphParser.RPAREN);
	            break;

	        }
	        this.state = 396;
	        this.match(graphParser.LCURL);
	        this.state = 397;
	        localctx.content = this.blockContent();
	        this.state = 398;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeDeclaration() {
	    let localctx = new NodeDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 28, graphParser.RULE_nodeDeclaration);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 401;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===56) {
	            this.state = 400;
	            localctx.start = this.match(graphParser.START);
	        }

	        this.state = 403;
	        this.match(graphParser.NODE);
	        this.state = 404;
	        localctx.name = this.nodeId();
	        this.state = 405;
	        this.match(graphParser.LCURL);
	        this.state = 407;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===15) {
	            this.state = 406;
	            this.comment();
	        }

	        this.state = 409;
	        localctx.content = this.nodeContent();
	        this.state = 410;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionDeclaration() {
	    let localctx = new DigressionDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 30, graphParser.RULE_digressionDeclaration);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 413;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===34) {
	            this.state = 412;
	            localctx.global = this.match(graphParser.GLOBAL);
	        }

	        this.state = 416;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===50) {
	            this.state = 415;
	            localctx.preprocessor = this.match(graphParser.PREPROCESSOR);
	        }

	        this.state = 418;
	        this.match(graphParser.DIGRESSION);
	        this.state = 419;
	        localctx.name = this.digressionId();
	        this.state = 420;
	        this.match(graphParser.LCURL);
	        this.state = 421;
	        this.digressionContent();
	        this.state = 422;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionContent() {
	    let localctx = new DigressionContentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 32, graphParser.RULE_digressionContent);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 424;
	        this.match(graphParser.CONDITIONS);
	        this.state = 425;
	        this.match(graphParser.LCURL);
	        this.state = 431;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===46) {
	            this.state = 426;
	            localctx._transitionDefinitionOnMessage = this.transitionDefinitionOnMessage();
	            localctx.transitions.push(localctx._transitionDefinitionOnMessage);
	            this.state = 427;
	            this.match(graphParser.SEMICOLON);
	            this.state = 433;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 434;
	        this.match(graphParser.RCURL);
	        this.state = 444;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===55 || _la===65) {
	            this.state = 438;
	            this._errHandler.sync(this);
	            switch(this._input.LA(1)) {
	            case 55:
	                this.state = 435;
	                this.match(graphParser.SHARED);
	                this.state = 436;
	                localctx._variableDefinition = this.variableDefinition();
	                localctx.sharedState.push(localctx._variableDefinition);
	                break;
	            case 65:
	                this.state = 437;
	                localctx._variableDefinition = this.variableDefinition();
	                localctx.copiedState.push(localctx._variableDefinition);
	                break;
	            default:
	                throw new antlr4.error.NoViableAltException(this);
	            }
	            this.state = 440;
	            this.match(graphParser.SEMICOLON);
	            this.state = 446;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 447;
	        this.nodeContent();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	comment() {
	    let localctx = new CommentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 34, graphParser.RULE_comment);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 449;
	        this.match(graphParser.COMMENT);
	        this.state = 450;
	        this.match(graphParser.StringLiteral);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeContent() {
	    let localctx = new NodeContentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 36, graphParser.RULE_nodeContent);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 452;
	        localctx.doSec = this.doSection();
	        this.state = 454;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===61) {
	            this.state = 453;
	            localctx.transitions = this.nodeTransitionSection();
	        }

	        this.state = 458;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===50) {
	            this.state = 456;
	            this.match(graphParser.PREPROCESSOR);
	            this.state = 457;
	            localctx.preExit = this.doSection();
	        }

	        this.state = 461;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===48) {
	            this.state = 460;
	            localctx.onExit = this.nodeOnExitSection();
	        }

	        this.state = 464;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===47) {
	            this.state = 463;
	            this.nodeOnDigressionReturnSection();
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeOnExitSection() {
	    let localctx = new NodeOnExitSectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 38, graphParser.RULE_nodeOnExitSection);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 466;
	        this.match(graphParser.ONEXIT);
	        this.state = 467;
	        this.match(graphParser.LCURL);
	        this.state = 471;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===106) {
	            this.state = 468;
	            this.nodeOnExitSectionItem();
	            this.state = 473;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 477;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===20) {
	            this.state = 474;
	            this.match(graphParser.DEFAULT);
	            this.state = 475;
	            this.match(graphParser.COLON);
	            this.state = 476;
	            localctx.defaultDo = this.onExitDoWithConfident();
	        }

	        this.state = 482;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===22) {
	            this.state = 479;
	            this.match(graphParser.DIGRESSION);
	            this.state = 480;
	            this.match(graphParser.COLON);
	            this.state = 481;
	            localctx.digressionDo = this.onExitDoWithConfident();
	        }

	        this.state = 484;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeOnExitSectionItem() {
	    let localctx = new NodeOnExitSectionItemContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 40, graphParser.RULE_nodeOnExitSectionItem);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 489; 
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        do {
	            this.state = 486;
	            this.transitionName();
	            this.state = 487;
	            this.match(graphParser.COLON);
	            this.state = 491; 
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        } while(_la===106);
	        this.state = 493;
	        this.onExitDoWithConfident();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	onExitDoWithConfident() {
	    let localctx = new OnExitDoWithConfidentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 42, graphParser.RULE_onExitDoWithConfident);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 495;
	        localctx.doSec = this.doSection();
	        this.state = 499;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===46) {
	            this.state = 496;
	            this.match(graphParser.ON);
	            this.state = 497;
	            this.match(graphParser.CONFIDENT);
	            this.state = 498;
	            localctx.confidentDo = this.doSection();
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeOnDigressionReturnSection() {
	    let localctx = new NodeOnDigressionReturnSectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 44, graphParser.RULE_nodeOnDigressionReturnSection);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 501;
	        this.match(graphParser.ONDIGRETURN);
	        this.state = 502;
	        this.blockBody();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeTransitionSection() {
	    let localctx = new NodeTransitionSectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 46, graphParser.RULE_nodeTransitionSection);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 504;
	        this.match(graphParser.TRANSITIONS);
	        this.state = 505;
	        this.match(graphParser.LCURL);
	        this.state = 509;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===106) {
	            this.state = 506;
	            localctx._namedTransitionDefinition = this.namedTransitionDefinition();
	            localctx.list.push(localctx._namedTransitionDefinition);
	            this.state = 511;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 512;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	namedTransitionDefinition() {
	    let localctx = new NamedTransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 48, graphParser.RULE_namedTransitionDefinition);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 514;
	        this.transitionName();
	        this.state = 515;
	        this.match(graphParser.COLON);
	        this.state = 516;
	        this.match(graphParser.GOTO);
	        this.state = 517;
	        localctx.target = this.nodeId();
	        this.state = 518;
	        this.transitionDefinition();
	        this.state = 519;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	transitionDefinition() {
	    let localctx = new TransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 50, graphParser.RULE_transitionDefinition);
	    try {
	        this.state = 523;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 46:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 521;
	            this.transitionDefinitionOnMessage();
	            break;
	        case 91:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 522;
	            this.unconditionalTransitionDefinition();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	transitionDefinitionOnMessage() {
	    let localctx = new TransitionDefinitionOnMessageContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 52, graphParser.RULE_transitionDefinitionOnMessage);
	    try {
	        this.state = 527;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,50,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 525;
	            this.conditionalTransitionDefinition();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 526;
	            this.timeoutTransitionDefinition();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	timeoutTransitionDefinition() {
	    let localctx = new TimeoutTransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 54, graphParser.RULE_timeoutTransitionDefinition);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 529;
	        this.match(graphParser.ON);
	        this.state = 530;
	        this.match(graphParser.TIMEOUT);
	        this.state = 531;
	        localctx.timeout = this.anyNumber();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	conditionalTransitionDefinition() {
	    let localctx = new ConditionalTransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 56, graphParser.RULE_conditionalTransitionDefinition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 533;
	        this.match(graphParser.ON);
	        this.state = 534;
	        localctx.condition = this.expression(0);
	        this.state = 537;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===51) {
	            this.state = 535;
	            this.match(graphParser.PRIORITY);
	            this.state = 536;
	            localctx.priority = this.match(graphParser.DecimalIntegerLiteral);
	        }

	        this.state = 541;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===67) {
	            this.state = 539;
	            this.match(graphParser.WHEN);
	            this.state = 540;
	            localctx.confident = this.match(graphParser.CONFIDENT);
	        }

	        this.state = 555;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===62) {
	            this.state = 543;
	            this.match(graphParser.TAGS);
	            this.state = 544;
	            this.match(graphParser.COLON);
	            this.state = 553;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===106) {
	                this.state = 545;
	                localctx._tagName = this.tagName();
	                localctx.tags.push(localctx._tagName);
	                this.state = 550;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                while(_la===92) {
	                    this.state = 546;
	                    this.match(graphParser.COMMA);
	                    this.state = 547;
	                    localctx._tagName = this.tagName();
	                    localctx.tags.push(localctx._tagName);
	                    this.state = 552;
	                    this._errHandler.sync(this);
	                    _la = this._input.LA(1);
	                }
	            }

	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	unconditionalTransitionDefinition() {
	    let localctx = new UnconditionalTransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 58, graphParser.RULE_unconditionalTransitionDefinition);
	    try {
	        this.enterOuterAlt(localctx, 1);

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	doSection() {
	    let localctx = new DoSectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 60, graphParser.RULE_doSection);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 559;
	        this.match(graphParser.DO);
	        this.state = 560;
	        this.blockBody();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockBody() {
	    let localctx = new BlockBodyContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 62, graphParser.RULE_blockBody);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 562;
	        this.match(graphParser.LCURL);
	        this.state = 566;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while((((_la) & ~0x1f) === 0 && ((1 << _la) & 1614815260) !== 0) || ((((_la - 32)) & ~0x1f) === 0 && ((1 << (_la - 32)) & 5243929) !== 0) || ((((_la - 65)) & ~0x1f) === 0 && ((1 << (_la - 65)) & 5325307) !== 0) || _la===105 || _la===106) {
	            this.state = 563;
	            localctx._oneOrGroupedCommand = this.oneOrGroupedCommand();
	            localctx.commands.push(localctx._oneOrGroupedCommand);
	            this.state = 568;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 569;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	oneOrGroupedCommand() {
	    let localctx = new OneOrGroupedCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 64, graphParser.RULE_oneOrGroupedCommand);
	    try {
	        this.state = 573;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,57,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 571;
	            this.nodeCommand();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 572;
	            this.blockBody();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeCommand() {
	    let localctx = new NodeCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 66, graphParser.RULE_nodeCommand);
	    try {
	        this.state = 578;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 2:
	        case 3:
	        case 4:
	        case 13:
	        case 22:
	        case 29:
	        case 30:
	        case 35:
	        case 42:
	        case 52:
	        case 54:
	        case 65:
	        case 66:
	        case 68:
	        case 69:
	        case 70:
	        case 71:
	        case 72:
	        case 73:
	        case 79:
	        case 81:
	        case 85:
	        case 87:
	        case 105:
	        case 106:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 575;
	            this.statement();
	            break;
	        case 36:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 576;
	            this.conditionalCommand();
	            break;
	        case 32:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 577;
	            this.cycleCommand();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	cycleCommand() {
	    let localctx = new CycleCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 68, graphParser.RULE_cycleCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 580;
	        this.foreachCommand();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statement() {
	    let localctx = new StatementContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 70, graphParser.RULE_statement);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 582;
	        this.statementBody();
	        this.state = 583;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statementBody() {
	    let localctx = new StatementBodyContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 72, graphParser.RULE_statementBody);
	    try {
	        this.state = 594;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,59,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 585;
	            this.waitCommand();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 586;
	            this.gotoCommand();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 587;
	            this.setCommand();
	            break;

	        case 4:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 588;
	            this.returnCommand();
	            break;

	        case 5:
	            this.enterOuterAlt(localctx, 5);
	            this.state = 589;
	            this.exitCommand();
	            break;

	        case 6:
	            this.enterOuterAlt(localctx, 6);
	            this.state = 590;
	            this.assignOpCommand();
	            break;

	        case 7:
	            this.enterOuterAlt(localctx, 7);
	            this.state = 591;
	            this.variableDefinition();
	            break;

	        case 8:
	            this.enterOuterAlt(localctx, 8);
	            this.state = 592;
	            this.logExpressionTypeCommand();
	            break;

	        case 9:
	            this.enterOuterAlt(localctx, 9);
	            this.state = 593;
	            this.expression(0);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	logExpressionTypeCommand() {
	    let localctx = new LogExpressionTypeCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 74, graphParser.RULE_logExpressionTypeCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 596;
	        this.match(graphParser.LOGEXPR);
	        this.state = 597;
	        this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionControl() {
	    let localctx = new DigressionControlContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 76, graphParser.RULE_digressionControl);
	    try {
	        this.state = 611;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,60,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new EnableDigressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 599;
	            this.match(graphParser.DIGRESSION);
	            this.state = 600;
	            this.match(graphParser.ENABLE);
	            this.state = 601;
	            localctx.spec = this.digressionSpec();
	            break;

	        case 2:
	            localctx = new DisableDigressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 602;
	            this.match(graphParser.DIGRESSION);
	            this.state = 603;
	            this.match(graphParser.DISABLE);
	            this.state = 604;
	            localctx.spec = this.digressionSpec();
	            break;

	        case 3:
	            localctx = new SaveDigressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 3);
	            this.state = 605;
	            this.match(graphParser.DIGRESSION);
	            this.state = 606;
	            this.match(graphParser.SAVE);
	            this.state = 607;
	            localctx.spec = this.digressionSpec();
	            break;

	        case 4:
	            localctx = new ApplyDigressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 4);
	            this.state = 608;
	            this.match(graphParser.DIGRESSION);
	            this.state = 609;
	            this.match(graphParser.APPLY);
	            this.state = 610;
	            localctx.mask = this.expression(0);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionSpec() {
	    let localctx = new DigressionSpecContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 78, graphParser.RULE_digressionSpec);
	    var _la = 0;
	    try {
	        this.state = 625;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 106:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 613;
	            localctx._digressionId = this.digressionId();
	            localctx.list.push(localctx._digressionId);
	            break;
	        case 87:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 614;
	            this.match(graphParser.LCURL);
	            this.state = 615;
	            localctx._digressionId = this.digressionId();
	            localctx.list.push(localctx._digressionId);
	            this.state = 620;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===92) {
	                this.state = 616;
	                this.match(graphParser.COMMA);
	                this.state = 617;
	                localctx._digressionId = this.digressionId();
	                localctx.list.push(localctx._digressionId);
	                this.state = 622;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 623;
	            this.match(graphParser.RCURL);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	exitCommand() {
	    let localctx = new ExitCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 80, graphParser.RULE_exitCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 627;
	        this.match(graphParser.EXIT);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	returnCommand() {
	    let localctx = new ReturnCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 82, graphParser.RULE_returnCommand);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 629;
	        this.match(graphParser.RETURN);
	        this.state = 631;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if((((_la) & ~0x1f) === 0 && ((1 << _la) & 541073436) !== 0) || ((((_la - 68)) & ~0x1f) === 0 && ((1 << (_la - 68)) & 665663) !== 0) || _la===105 || _la===106) {
	            this.state = 630;
	            localctx.returnValue = this.expression(0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	variableDefinition() {
	    let localctx = new VariableDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 84, graphParser.RULE_variableDefinition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 633;
	        this.variableDeclaration();
	        this.state = 636;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===90) {
	            this.state = 634;
	            this.match(graphParser.COLON);
	            this.state = 635;
	            localctx.type = this.typeDescription();
	        }

	        this.state = 640;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===95) {
	            this.state = 638;
	            this.match(graphParser.EQUALSIGN);
	            this.state = 639;
	            localctx.value = this.expression(0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	variableDeclaration() {
	    let localctx = new VariableDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 86, graphParser.RULE_variableDeclaration);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 642;
	        this.match(graphParser.VAR);
	        this.state = 643;
	        this.variableName();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeDescription() {
	    let localctx = new TypeDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 88, graphParser.RULE_typeDescription);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 645;
	        this.type_();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	conditionalCommand() {
	    let localctx = new ConditionalCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 90, graphParser.RULE_conditionalCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 647;
	        this.match(graphParser.IF);
	        this.state = 648;
	        this.match(graphParser.LPAREN);
	        this.state = 649;
	        localctx.condition = this.expression(0);
	        this.state = 650;
	        this.match(graphParser.RPAREN);
	        this.state = 651;
	        localctx.thenCommand = this.oneOrGroupedCommand();
	        this.state = 654;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,66,this._ctx);
	        if(la_===1) {
	            this.state = 652;
	            this.match(graphParser.ELSE);
	            this.state = 653;
	            localctx.elseCommand = this.oneOrGroupedCommand();

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	foreachCommand() {
	    let localctx = new ForeachCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 92, graphParser.RULE_foreachCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 656;
	        this.match(graphParser.FOR);
	        this.state = 657;
	        this.match(graphParser.LPAREN);
	        this.state = 658;
	        this.variableDeclaration();
	        this.state = 659;
	        this.match(graphParser.IN);
	        this.state = 660;
	        localctx.container = this.expression(0);
	        this.state = 661;
	        this.match(graphParser.RPAREN);
	        this.state = 662;
	        localctx.body = this.oneOrGroupedCommand();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	waitCommand() {
	    let localctx = new WaitCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 94, graphParser.RULE_waitCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 664;
	        this.match(graphParser.WAIT);
	        this.state = 665;
	        this.activatedTransitionList();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	activatedTransitionList() {
	    let localctx = new ActivatedTransitionListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 96, graphParser.RULE_activatedTransitionList);
	    var _la = 0;
	    try {
	        this.state = 681;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 87:
	            localctx = new SelectedActivatedTransitionListContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 667;
	            this.match(graphParser.LCURL);
	            this.state = 668;
	            this.transitionName();
	            this.state = 675;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===92 || _la===106) {
	                this.state = 670;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                if(_la===92) {
	                    this.state = 669;
	                    this.match(graphParser.COMMA);
	                }

	                this.state = 672;
	                this.transitionName();
	                this.state = 677;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 678;
	            this.match(graphParser.RCURL);
	            break;
	        case 5:
	            localctx = new AllActivatedTransitionListContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 680;
	            this.match(graphParser.STAR);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	gotoCommand() {
	    let localctx = new GotoCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 98, graphParser.RULE_gotoCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 683;
	        this.match(graphParser.GOTO);
	        this.state = 684;
	        this.transitionName();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	setCommand() {
	    let localctx = new SetCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 100, graphParser.RULE_setCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 686;
	        this.match(graphParser.SET);
	        this.state = 687;
	        localctx.ref = this.lValue(0);
	        this.state = 688;
	        this.match(graphParser.EQUALSIGN);
	        this.state = 689;
	        localctx.rightPartExpression = this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	assignOpCommand() {
	    let localctx = new AssignOpCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 102, graphParser.RULE_assignOpCommand);
	    try {
	        this.state = 701;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,70,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 691;
	            this.match(graphParser.SET);
	            this.state = 692;
	            localctx.ref = this.lValue(0);
	            this.state = 693;
	            localctx.op = this.match(graphParser.AddOp);
	            this.state = 694;
	            localctx.rightPartExpression = this.expression(0);
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 696;
	            this.match(graphParser.SET);
	            this.state = 697;
	            localctx.ref = this.lValue(0);
	            this.state = 698;
	            localctx.op = this.match(graphParser.RemoveOp);
	            this.state = 699;
	            localctx.rightPartExpression = this.expression(0);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	lValue(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new LValueContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 104;
	    this.enterRecursionRule(localctx, 104, graphParser.RULE_lValue, _p);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 709;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 85:
	            localctx = new LValueParenthesizedExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 704;
	            this.match(graphParser.LPAREN);
	            this.state = 705;
	            this.lValue(0);
	            this.state = 706;
	            this.match(graphParser.RPAREN);
	            break;
	        case 22:
	        case 69:
	        case 70:
	        case 106:
	            localctx = new LValueReferenceExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 708;
	            this.reference();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 725;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,73,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 723;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,72,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new LValueDotAccessExpressionContext(this, new LValueContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_lValue);
	                    this.state = 711;
	                    if (!( this.precpred(this._ctx, 3))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 3)");
	                    }
	                    this.state = 712;
	                    this.match(graphParser.PERIOD);
	                    this.state = 713;
	                    localctx.member = this.memberName();
	                    break;

	                case 2:
	                    localctx = new LValueLiteralIndexAccessExpressionContext(this, new LValueContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_lValue);
	                    this.state = 714;
	                    if (!( this.precpred(this._ctx, 2))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 2)");
	                    }
	                    this.state = 715;
	                    this.match(graphParser.LBRACKET);
	                    this.state = 716;
	                    localctx.index = this.match(graphParser.DecimalIntegerLiteral);
	                    this.state = 717;
	                    this.match(graphParser.RBRACKET);
	                    break;

	                case 3:
	                    localctx = new LValueExpressionIndexAccessExpressionContext(this, new LValueContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_lValue);
	                    this.state = 718;
	                    if (!( this.precpred(this._ctx, 1))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 1)");
	                    }
	                    this.state = 719;
	                    this.match(graphParser.LBRACKET);
	                    this.state = 720;
	                    localctx.index = this.expression(0);
	                    this.state = 721;
	                    this.match(graphParser.RBRACKET);
	                    break;

	                } 
	            }
	            this.state = 727;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,73,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}


	expression(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new ExpressionContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 106;
	    this.enterRecursionRule(localctx, 106, graphParser.RULE_expression, _p);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 776;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,79,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new ExternFunctionCallExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 729;
	            this.externalFunctionCall();
	            break;

	        case 2:
	            localctx = new BuiltinFunctionCallExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 730;
	            this.builtinFunctionCall();
	            break;

	        case 3:
	            localctx = new BlockFunctionCallExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 731;
	            this.blockFunctionCall();
	            break;

	        case 4:
	            localctx = new BooleanLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 732;
	            this.match(graphParser.BooleanLiteral);
	            break;

	        case 5:
	            localctx = new SlotFillingCallExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 733;
	            this.slotFillingCall();
	            break;

	        case 6:
	            localctx = new NumberLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 734;
	            this.anyNumber();
	            break;

	        case 7:
	            localctx = new StringLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 735;
	            this.match(graphParser.StringLiteral);
	            break;

	        case 8:
	            localctx = new ParenthesizedExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 736;
	            this.match(graphParser.LPAREN);
	            this.state = 737;
	            this.expression(0);
	            this.state = 738;
	            this.match(graphParser.RPAREN);
	            break;

	        case 9:
	            localctx = new ReferenceExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 740;
	            this.reference();
	            break;

	        case 10:
	            localctx = new DigressionControlExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 741;
	            this.digressionControl();
	            break;

	        case 11:
	            localctx = new UnaryOpExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 742;
	            localctx.op = this._input.LT(1);
	            _la = this._input.LA(1);
	            if(!(_la===3 || _la===4 || _la===105)) {
	                localctx.op = this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            this.state = 743;
	            this.expression(15);
	            break;

	        case 12:
	            localctx = new IsDefinedExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 744;
	            this.reference();
	            this.state = 745;
	            this.match(graphParser.IS);
	            this.state = 747;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===44) {
	                this.state = 746;
	                this.match(graphParser.NOT);
	            }

	            this.state = 749;
	            this.match(graphParser.DEFINED);
	            break;

	        case 13:
	            localctx = new ObjectLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 751;
	            this.match(graphParser.LCURL);
	            this.state = 760;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===106) {
	                this.state = 752;
	                localctx._objectPropertyExpressionPart = this.objectPropertyExpressionPart();
	                localctx.fields.push(localctx._objectPropertyExpressionPart);
	                this.state = 757;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                while(_la===92) {
	                    this.state = 753;
	                    this.match(graphParser.COMMA);
	                    this.state = 754;
	                    localctx._objectPropertyExpressionPart = this.objectPropertyExpressionPart();
	                    localctx.fields.push(localctx._objectPropertyExpressionPart);
	                    this.state = 759;
	                    this._errHandler.sync(this);
	                    _la = this._input.LA(1);
	                }
	            }

	            this.state = 762;
	            this.match(graphParser.RCURL);
	            break;

	        case 14:
	            localctx = new TupleLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 763;
	            this.match(graphParser.LBRACKET);
	            this.state = 765;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if((((_la) & ~0x1f) === 0 && ((1 << _la) & 541073436) !== 0) || ((((_la - 68)) & ~0x1f) === 0 && ((1 << (_la - 68)) & 665663) !== 0) || _la===105 || _la===106) {
	                this.state = 764;
	                localctx._expression = this.expression(0);
	                localctx.elements.push(localctx._expression);
	            }

	            this.state = 771;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===92) {
	                this.state = 767;
	                this.match(graphParser.COMMA);
	                this.state = 768;
	                localctx._expression = this.expression(0);
	                localctx.elements.push(localctx._expression);
	                this.state = 773;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 774;
	            this.match(graphParser.RBRACKET);
	            break;

	        case 15:
	            localctx = new NullLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 775;
	            this.match(graphParser.NULL);
	            break;

	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 844;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,82,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 842;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,81,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 778;
	                    if (!( this.precpred(this._ctx, 12))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 12)");
	                    }
	                    this.state = 779;
	                    localctx.op = this._input.LT(1);
	                    _la = this._input.LA(1);
	                    if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 224) !== 0))) {
	                        localctx.op = this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 780;
	                    localctx.right = this.expression(13);
	                    break;

	                case 2:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 781;
	                    if (!( this.precpred(this._ctx, 11))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 11)");
	                    }
	                    this.state = 782;
	                    localctx.op = this._input.LT(1);
	                    _la = this._input.LA(1);
	                    if(!(_la===3 || _la===4)) {
	                        localctx.op = this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 783;
	                    localctx.right = this.expression(12);
	                    break;

	                case 3:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 784;
	                    if (!( this.precpred(this._ctx, 10))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 10)");
	                    }
	                    this.state = 785;
	                    localctx.op = this._input.LT(1);
	                    _la = this._input.LA(1);
	                    if(!(((((_la - 82)) & ~0x1f) === 0 && ((1 << (_la - 82)) & 786435) !== 0))) {
	                        localctx.op = this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 786;
	                    localctx.right = this.expression(11);
	                    break;

	                case 4:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 787;
	                    if (!( this.precpred(this._ctx, 9))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 9)");
	                    }
	                    this.state = 788;
	                    localctx.op = this._input.LT(1);
	                    _la = this._input.LA(1);
	                    if(!(_la===98 || _la===99)) {
	                        localctx.op = this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 789;
	                    localctx.right = this.expression(10);
	                    break;

	                case 5:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 790;
	                    if (!( this.precpred(this._ctx, 8))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 8)");
	                    }
	                    this.state = 791;
	                    localctx.op = this.match(graphParser.AndOp);
	                    this.state = 792;
	                    localctx.right = this.expression(9);
	                    break;

	                case 6:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 793;
	                    if (!( this.precpred(this._ctx, 7))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 7)");
	                    }
	                    this.state = 794;
	                    localctx.op = this.match(graphParser.OrOp);
	                    this.state = 795;
	                    localctx.right = this.expression(8);
	                    break;

	                case 7:
	                    localctx = new NullCollateExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.nullable = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 796;
	                    if (!( this.precpred(this._ctx, 6))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 6)");
	                    }
	                    this.state = 797;
	                    this.match(graphParser.QMARK);
	                    this.state = 798;
	                    this.match(graphParser.QMARK);
	                    this.state = 799;
	                    localctx.defaultValue = this.expression(7);
	                    break;

	                case 8:
	                    localctx = new TernaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.condition = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 800;
	                    if (!( this.precpred(this._ctx, 5))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 5)");
	                    }
	                    this.state = 801;
	                    this.match(graphParser.QMARK);
	                    this.state = 802;
	                    localctx.thenExpression = this.expression(0);
	                    this.state = 803;
	                    this.match(graphParser.COLON);
	                    this.state = 804;
	                    localctx.elseExpression = this.expression(5);
	                    break;

	                case 9:
	                    localctx = new DotAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 806;
	                    if (!( this.precpred(this._ctx, 23))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 23)");
	                    }
	                    this.state = 807;
	                    this.match(graphParser.PERIOD);
	                    this.state = 808;
	                    localctx.member = this.memberName();
	                    break;

	                case 10:
	                    localctx = new OptionalDotAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 809;
	                    if (!( this.precpred(this._ctx, 22))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 22)");
	                    }
	                    this.state = 810;
	                    this.match(graphParser.QMARK);
	                    this.state = 811;
	                    this.match(graphParser.PERIOD);
	                    this.state = 812;
	                    localctx.member = this.memberName();
	                    break;

	                case 11:
	                    localctx = new CallDotAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 813;
	                    if (!( this.precpred(this._ctx, 21))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 21)");
	                    }
	                    this.state = 814;
	                    this.match(graphParser.PERIOD);
	                    this.state = 815;
	                    localctx.member = this.memberName();
	                    this.state = 816;
	                    localctx.arguments = this.argumentInvokation();
	                    break;

	                case 12:
	                    localctx = new OptionalCallDotAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 818;
	                    if (!( this.precpred(this._ctx, 20))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 20)");
	                    }
	                    this.state = 819;
	                    this.match(graphParser.QMARK);
	                    this.state = 820;
	                    this.match(graphParser.PERIOD);
	                    this.state = 821;
	                    localctx.member = this.memberName();
	                    this.state = 822;
	                    localctx.arguments = this.argumentInvokation();
	                    break;

	                case 13:
	                    localctx = new LiteralIndexAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 824;
	                    if (!( this.precpred(this._ctx, 19))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 19)");
	                    }
	                    this.state = 825;
	                    this.match(graphParser.LBRACKET);
	                    this.state = 826;
	                    localctx.index = this.match(graphParser.DecimalIntegerLiteral);
	                    this.state = 827;
	                    this.match(graphParser.RBRACKET);
	                    break;

	                case 14:
	                    localctx = new ExpressionIndexAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 828;
	                    if (!( this.precpred(this._ctx, 18))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 18)");
	                    }
	                    this.state = 829;
	                    this.match(graphParser.LBRACKET);
	                    this.state = 830;
	                    localctx.index = this.expression(0);
	                    this.state = 831;
	                    this.match(graphParser.RBRACKET);
	                    break;

	                case 15:
	                    localctx = new IsNullExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 833;
	                    if (!( this.precpred(this._ctx, 14))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 14)");
	                    }
	                    this.state = 834;
	                    this.match(graphParser.IS);
	                    this.state = 836;
	                    this._errHandler.sync(this);
	                    _la = this._input.LA(1);
	                    if(_la===44) {
	                        this.state = 835;
	                        this.match(graphParser.NOT);
	                    }

	                    this.state = 838;
	                    this.match(graphParser.NULL);
	                    break;

	                case 16:
	                    localctx = new CastExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 839;
	                    if (!( this.precpred(this._ctx, 1))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 1)");
	                    }
	                    this.state = 840;
	                    this.match(graphParser.AS);
	                    this.state = 841;
	                    this.typeDescription();
	                    break;

	                } 
	            }
	            this.state = 846;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,82,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}



	objectPropertyExpressionPart() {
	    let localctx = new ObjectPropertyExpressionPartContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 108, graphParser.RULE_objectPropertyExpressionPart);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 847;
	        localctx.name = this.propertyName();
	        this.state = 848;
	        this.match(graphParser.COLON);
	        this.state = 849;
	        this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	externalFunctionCall() {
	    let localctx = new ExternalFunctionCallContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 110, graphParser.RULE_externalFunctionCall);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 851;
	        this.match(graphParser.EXTERNAL);
	        this.state = 852;
	        localctx.name = this.externalFunctionName();
	        this.state = 853;
	        localctx.arguments = this.argumentInvokation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockFunctionCall() {
	    let localctx = new BlockFunctionCallContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 112, graphParser.RULE_blockFunctionCall);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 855;
	        this.match(graphParser.BLOCKCALL);
	        this.state = 856;
	        localctx.name = this.blockId();
	        this.state = 857;
	        localctx.arguments = this.argumentInvokation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	slotFillingCall() {
	    let localctx = new SlotFillingCallContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 114, graphParser.RULE_slotFillingCall);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 859;
	        this.match(graphParser.FILL);
	        this.state = 860;
	        localctx.name = this.typeAlias();
	        this.state = 861;
	        localctx.arguments = this.argumentInvokation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	builtinFunctionCall() {
	    let localctx = new BuiltinFunctionCallContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 116, graphParser.RULE_builtinFunctionCall);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 863;
	        this.match(graphParser.BuiltinMark);
	        this.state = 864;
	        localctx.name = this.builtinFunctionName();
	        this.state = 865;
	        localctx.arguments = this.argumentInvokation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	argumentInvokation() {
	    let localctx = new ArgumentInvokationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 118, graphParser.RULE_argumentInvokation);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 867;
	        this.match(graphParser.LPAREN);
	        this.state = 868;
	        this.argumentList();
	        this.state = 869;
	        this.match(graphParser.RPAREN);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	argumentList() {
	    let localctx = new ArgumentListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 120, graphParser.RULE_argumentList);
	    var _la = 0;
	    try {
	        this.state = 878;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,84,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 871;
	            localctx.positional = this.positionalArgumentList();
	            this.state = 874;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===92) {
	                this.state = 872;
	                this.match(graphParser.COMMA);
	                this.state = 873;
	                localctx.named = this.namedArgumentList();
	            }

	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 876;
	            localctx.named = this.namedArgumentList();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);

	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	namedArgumentList() {
	    let localctx = new NamedArgumentListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 122, graphParser.RULE_namedArgumentList);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 880;
	        localctx._namedArgument = this.namedArgument();
	        localctx.arguments.push(localctx._namedArgument);
	        this.state = 885;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===92) {
	            this.state = 881;
	            this.match(graphParser.COMMA);
	            this.state = 882;
	            localctx._namedArgument = this.namedArgument();
	            localctx.arguments.push(localctx._namedArgument);
	            this.state = 887;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	positionalArgumentList() {
	    let localctx = new PositionalArgumentListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 124, graphParser.RULE_positionalArgumentList);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 888;
	        localctx._positionalArgument = this.positionalArgument();
	        localctx.arguments.push(localctx._positionalArgument);
	        this.state = 893;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,86,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 889;
	                this.match(graphParser.COMMA);
	                this.state = 890;
	                localctx._positionalArgument = this.positionalArgument();
	                localctx.arguments.push(localctx._positionalArgument); 
	            }
	            this.state = 895;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,86,this._ctx);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	positionalArgument() {
	    let localctx = new PositionalArgumentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 126, graphParser.RULE_positionalArgument);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 896;
	        this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	namedArgument() {
	    let localctx = new NamedArgumentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 128, graphParser.RULE_namedArgument);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 898;
	        this.argumentName();
	        this.state = 899;
	        this.match(graphParser.COLON);
	        this.state = 900;
	        this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	parameterList() {
	    let localctx = new ParameterListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 130, graphParser.RULE_parameterList);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 910;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===106) {
	            this.state = 902;
	            localctx._parameter = this.parameter();
	            localctx.parameters.push(localctx._parameter);
	            this.state = 907;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===92) {
	                this.state = 903;
	                this.match(graphParser.COMMA);
	                this.state = 904;
	                localctx._parameter = this.parameter();
	                localctx.parameters.push(localctx._parameter);
	                this.state = 909;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	parameter() {
	    let localctx = new ParameterContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 132, graphParser.RULE_parameter);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 912;
	        localctx.name = this.parameterName();
	        this.state = 913;
	        this.match(graphParser.COLON);
	        this.state = 914;
	        localctx.type = this.typeDescription();
	        this.state = 917;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===95) {
	            this.state = 915;
	            this.match(graphParser.EQUALSIGN);
	            this.state = 916;
	            localctx.defaultValue = this.expression(0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	reference() {
	    let localctx = new ReferenceContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 134, graphParser.RULE_reference);
	    try {
	        this.state = 939;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 69:
	            localctx = new BuiltinReferenceContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 919;
	            this.match(graphParser.BuiltinMark);
	            this.state = 920;
	            localctx.refName = this.referenceName();
	            break;
	        case 106:
	            localctx = new LocalReferenceContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 921;
	            localctx.refName = this.referenceName();
	            break;
	        case 70:
	            localctx = new ContextReferenceContext(this, localctx);
	            this.enterOuterAlt(localctx, 3);
	            this.state = 922;
	            this.match(graphParser.ContextMark);
	            this.state = 924;
	            this._errHandler.sync(this);
	            var la_ = this._interp.adaptivePredict(this._input,90,this._ctx);
	            if(la_===1) {
	                this.state = 923;
	                localctx.refName = this.referenceName();

	            }
	            break;
	        case 22:
	            localctx = new DigressionReferenceContext(this, localctx);
	            this.enterOuterAlt(localctx, 4);
	            this.state = 926;
	            this.match(graphParser.DIGRESSION);
	            this.state = 937;
	            this._errHandler.sync(this);
	            var la_ = this._interp.adaptivePredict(this._input,93,this._ctx);
	            if(la_===1) {
	                this.state = 927;
	                this.match(graphParser.PERIOD);
	                this.state = 928;
	                localctx.name = this.digressionId();
	                this.state = 931;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,91,this._ctx);
	                if(la_===1) {
	                    this.state = 929;
	                    this.match(graphParser.PERIOD);
	                    this.state = 930;
	                    localctx.shared = this.match(graphParser.SHARED);

	                }
	                this.state = 935;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,92,this._ctx);
	                if(la_===1) {
	                    this.state = 933;
	                    this.match(graphParser.PERIOD);
	                    this.state = 934;
	                    localctx.refName = this.referenceName();

	                }

	            }
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeId() {
	    let localctx = new NodeIdContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 136, graphParser.RULE_nodeId);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 941;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockId() {
	    let localctx = new BlockIdContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 138, graphParser.RULE_blockId);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 943;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionId() {
	    let localctx = new DigressionIdContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 140, graphParser.RULE_digressionId);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 945;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	transitionName() {
	    let localctx = new TransitionNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 142, graphParser.RULE_transitionName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 947;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	referenceName() {
	    let localctx = new ReferenceNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 144, graphParser.RULE_referenceName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 949;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	memberName() {
	    let localctx = new MemberNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 146, graphParser.RULE_memberName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 951;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	tagName() {
	    let localctx = new TagNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 148, graphParser.RULE_tagName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 953;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	factName() {
	    let localctx = new FactNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 150, graphParser.RULE_factName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 955;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	fieldName() {
	    let localctx = new FieldNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 152, graphParser.RULE_fieldName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 957;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	variableName() {
	    let localctx = new VariableNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 154, graphParser.RULE_variableName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 959;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	argumentName() {
	    let localctx = new ArgumentNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 156, graphParser.RULE_argumentName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 961;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	parameterName() {
	    let localctx = new ParameterNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 158, graphParser.RULE_parameterName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 963;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	propertyName() {
	    let localctx = new PropertyNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 160, graphParser.RULE_propertyName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 965;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	builtinFunctionName() {
	    let localctx = new BuiltinFunctionNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 162, graphParser.RULE_builtinFunctionName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 967;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	externalFunctionName() {
	    let localctx = new ExternalFunctionNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 164, graphParser.RULE_externalFunctionName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 969;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	functionName() {
	    let localctx = new FunctionNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 166, graphParser.RULE_functionName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 971;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	anyNumber() {
	    let localctx = new AnyNumberContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 168, graphParser.RULE_anyNumber);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 973;
	        _la = this._input.LA(1);
	        if(!(_la===71 || _la===72)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeEof() {
	    let localctx = new TypeEofContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 170, graphParser.RULE_typeEof);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 975;
	        this.type_();
	        this.state = 976;
	        this.match(graphParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	type_() {
	    let localctx = new Type_Context(this, this._ctx, this.state);
	    this.enterRule(localctx, 172, graphParser.RULE_type_);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 978;
	        localctx._nonNullableType = this.nonNullableType(0);
	        localctx.types.push(localctx._nonNullableType);
	        this.state = 979;
	        localctx._questionMarkOptional = this.questionMarkOptional();
	        localctx.nullableFlags.push(localctx._questionMarkOptional);
	        this.state = 986;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,95,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 980;
	                this.match(graphParser.TypeOr);
	                this.state = 981;
	                localctx._nonNullableType = this.nonNullableType(0);
	                localctx.types.push(localctx._nonNullableType);
	                this.state = 982;
	                localctx._questionMarkOptional = this.questionMarkOptional();
	                localctx.nullableFlags.push(localctx._questionMarkOptional); 
	            }
	            this.state = 988;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,95,this._ctx);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	questionMarkOptional() {
	    let localctx = new QuestionMarkOptionalContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 174, graphParser.RULE_questionMarkOptional);
	    try {
	        this.state = 991;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,96,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new PresentQuestionMarkContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 989;
	            this.match(graphParser.QMARK);
	            break;

	        case 2:
	            localctx = new NoQuestionMarkContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);

	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	objectTypeElementSeparator() {
	    let localctx = new ObjectTypeElementSeparatorContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 176, graphParser.RULE_objectTypeElementSeparator);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 993;
	        _la = this._input.LA(1);
	        if(!(_la===91 || _la===92)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	nonNullableType(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new NonNullableTypeContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 178;
	    this.enterRecursionRule(localctx, 178, graphParser.RULE_nonNullableType, _p);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1050;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 85:
	            localctx = new ParenthesisedTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 996;
	            this.match(graphParser.LPAREN);
	            this.state = 997;
	            this.type_();
	            this.state = 998;
	            this.match(graphParser.RPAREN);
	            break;
	        case 74:
	        case 75:
	        case 76:
	        case 77:
	        case 78:
	            localctx = new PredefinedTypeRefContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1000;
	            this.predefinedType();
	            break;
	        case 87:
	            localctx = new ObjectTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1001;
	            this.match(graphParser.LCURL);
	            this.state = 1007;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,97,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 1002;
	                    localctx._propertySignature = this.propertySignature();
	                    localctx.properties.push(localctx._propertySignature);
	                    this.state = 1003;
	                    this.objectTypeElementSeparator(); 
	                }
	                this.state = 1009;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,97,this._ctx);
	            }

	            this.state = 1013;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===81) {
	                this.state = 1010;
	                this.indexSignature();
	                this.state = 1011;
	                this.objectTypeElementSeparator();
	            }

	            this.state = 1020;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===106) {
	                this.state = 1015;
	                localctx._propertySignature = this.propertySignature();
	                localctx.properties.push(localctx._propertySignature);
	                this.state = 1016;
	                this.objectTypeElementSeparator();
	                this.state = 1022;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 1023;
	            this.match(graphParser.RCURL);
	            break;
	        case 81:
	            localctx = new TupleTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1024;
	            this.match(graphParser.LBRACKET);
	            this.state = 1026;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===2 || ((((_la - 74)) & ~0x1f) === 0 && ((1 << (_la - 74)) & 10911) !== 0) || _la===106) {
	                this.state = 1025;
	                localctx._type_ = this.type_();
	                localctx.elementTypes.push(localctx._type_);
	            }

	            this.state = 1032;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===92) {
	                this.state = 1028;
	                this.match(graphParser.COMMA);
	                this.state = 1029;
	                localctx._type_ = this.type_();
	                localctx.elementTypes.push(localctx._type_);
	                this.state = 1034;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 1035;
	            this.match(graphParser.RBRACKET);
	            break;
	        case 83:
	            localctx = new AngleUnionTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1036;
	            this.match(graphParser.LANGLE);
	            this.state = 1038;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===2 || ((((_la - 74)) & ~0x1f) === 0 && ((1 << (_la - 74)) & 10911) !== 0) || _la===106) {
	                this.state = 1037;
	                localctx._type_ = this.type_();
	                localctx.types.push(localctx._type_);
	            }

	            this.state = 1044;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===103) {
	                this.state = 1040;
	                this.match(graphParser.TypeOr);
	                this.state = 1041;
	                localctx._type_ = this.type_();
	                localctx.types.push(localctx._type_);
	                this.state = 1046;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 1047;
	            this.match(graphParser.RANGLE);
	            break;
	        case 2:
	            localctx = new StringLiteralTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1048;
	            localctx.value = this.match(graphParser.StringLiteral);
	            break;
	        case 106:
	            localctx = new TypeReferenceContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1049;
	            this.typeAlias();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 1060;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,106,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                localctx = new ArrayTypeContext(this, new NonNullableTypeContext(this, _parentctx, _parentState));
	                this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_nonNullableType);
	                this.state = 1052;
	                if (!( this.precpred(this._ctx, 5))) {
	                    throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 5)");
	                }
	                this.state = 1054;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                if(_la===93) {
	                    this.state = 1053;
	                    localctx.nullable = this.match(graphParser.QMARK);
	                }

	                this.state = 1056;
	                this.match(graphParser.LBRACKET);
	                this.state = 1057;
	                this.match(graphParser.RBRACKET); 
	            }
	            this.state = 1062;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,106,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}



	predefinedType() {
	    let localctx = new PredefinedTypeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 180, graphParser.RULE_predefinedType);
	    try {
	        this.state = 1068;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 78:
	            localctx = new UnknownTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 1063;
	            this.match(graphParser.Unknown);
	            break;
	        case 75:
	            localctx = new NumberTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 1064;
	            this.match(graphParser.Number);
	            break;
	        case 76:
	            localctx = new BooleanTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 3);
	            this.state = 1065;
	            this.match(graphParser.Boolean);
	            break;
	        case 77:
	            localctx = new StringTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 4);
	            this.state = 1066;
	            this.match(graphParser.String);
	            break;
	        case 74:
	            localctx = new EmptyTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 5);
	            this.state = 1067;
	            this.match(graphParser.Empty);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	propertySignature() {
	    let localctx = new PropertySignatureContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 182, graphParser.RULE_propertySignature);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1070;
	        this.propertyName();
	        this.state = 1072;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===93) {
	            this.state = 1071;
	            this.match(graphParser.QMARK);
	        }

	        this.state = 1074;
	        this.typeAnnotation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	indexSignature() {
	    let localctx = new IndexSignatureContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 184, graphParser.RULE_indexSignature);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1076;
	        this.match(graphParser.LBRACKET);
	        this.state = 1077;
	        this.match(graphParser.ID);
	        this.state = 1078;
	        this.match(graphParser.COLON);
	        this.state = 1079;
	        this.match(graphParser.String);
	        this.state = 1080;
	        this.match(graphParser.RBRACKET);
	        this.state = 1081;
	        this.typeAnnotation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeAnnotation() {
	    let localctx = new TypeAnnotationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 186, graphParser.RULE_typeAnnotation);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1083;
	        this.match(graphParser.COLON);
	        this.state = 1084;
	        this.type_();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeAlias() {
	    let localctx = new TypeAliasContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 188, graphParser.RULE_typeAlias);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1086;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


}

graphParser.EOF = antlr4.Token.EOF;
graphParser.T__0 = 1;
graphParser.StringLiteral = 2;
graphParser.PLUS = 3;
graphParser.MINUS = 4;
graphParser.STAR = 5;
graphParser.SLASH = 6;
graphParser.PERCENT = 7;
graphParser.APPLY = 8;
graphParser.WITH = 9;
graphParser.AS = 10;
graphParser.ASYNC = 11;
graphParser.BLOCK = 12;
graphParser.BLOCKCALL = 13;
graphParser.CATCH = 14;
graphParser.COMMENT = 15;
graphParser.CONDITIONS = 16;
graphParser.CONFIDENT = 17;
graphParser.CONTEXT = 18;
graphParser.COW = 19;
graphParser.DEFAULT = 20;
graphParser.DEFINED = 21;
graphParser.DIGRESSION = 22;
graphParser.DIGRESSIONS = 23;
graphParser.DISABLE = 24;
graphParser.DO = 25;
graphParser.DONE = 26;
graphParser.ELSE = 27;
graphParser.ENABLE = 28;
graphParser.EXTERNAL = 29;
graphParser.EXIT = 30;
graphParser.FINALLY = 31;
graphParser.FOR = 32;
graphParser.FUNCTION = 33;
graphParser.GLOBAL = 34;
graphParser.GOTO = 35;
graphParser.IF = 36;
graphParser.IMPORT = 37;
graphParser.IN = 38;
graphParser.INPUT = 39;
graphParser.IS = 40;
graphParser.LIBRARY = 41;
graphParser.LOGEXPR = 42;
graphParser.NODE = 43;
graphParser.NOT = 44;
graphParser.OF = 45;
graphParser.ON = 46;
graphParser.ONDIGRETURN = 47;
graphParser.ONEXIT = 48;
graphParser.OUTPUT = 49;
graphParser.PREPROCESSOR = 50;
graphParser.PRIORITY = 51;
graphParser.RETURN = 52;
graphParser.SAVE = 53;
graphParser.SET = 54;
graphParser.SHARED = 55;
graphParser.START = 56;
graphParser.STATIC = 57;
graphParser.SYNC = 58;
graphParser.SYSTEM = 59;
graphParser.TIMEOUT = 60;
graphParser.TRANSITIONS = 61;
graphParser.TAGS = 62;
graphParser.TRY = 63;
graphParser.TYPE = 64;
graphParser.VAR = 65;
graphParser.WAIT = 66;
graphParser.WHEN = 67;
graphParser.FILL = 68;
graphParser.BuiltinMark = 69;
graphParser.ContextMark = 70;
graphParser.DecimalLiteral = 71;
graphParser.DecimalIntegerLiteral = 72;
graphParser.BooleanLiteral = 73;
graphParser.Empty = 74;
graphParser.Number = 75;
graphParser.Boolean = 76;
graphParser.String = 77;
graphParser.Unknown = 78;
graphParser.NULL = 79;
graphParser.RBRACKET = 80;
graphParser.LBRACKET = 81;
graphParser.RANGLE = 82;
graphParser.LANGLE = 83;
graphParser.RPAREN = 84;
graphParser.LPAREN = 85;
graphParser.RCURL = 86;
graphParser.LCURL = 87;
graphParser.QUOTE = 88;
graphParser.BACKTRICK = 89;
graphParser.COLON = 90;
graphParser.SEMICOLON = 91;
graphParser.COMMA = 92;
graphParser.QMARK = 93;
graphParser.PERIOD = 94;
graphParser.EQUALSIGN = 95;
graphParser.AddOp = 96;
graphParser.RemoveOp = 97;
graphParser.EqualOp = 98;
graphParser.NotEqualOp = 99;
graphParser.GreaterEqualOp = 100;
graphParser.LessEqualOp = 101;
graphParser.OrOp = 102;
graphParser.TypeOr = 103;
graphParser.AndOp = 104;
graphParser.NotOperator = 105;
graphParser.ID = 106;
graphParser.LineComment = 107;
graphParser.DocsComment = 108;
graphParser.BlockComment = 109;
graphParser.Whitespace = 110;

graphParser.RULE_file = 0;
graphParser.RULE_referenceFile = 1;
graphParser.RULE_expressionFile = 2;
graphParser.RULE_blockContent = 3;
graphParser.RULE_systemDescription = 4;
graphParser.RULE_systemElementDescription = 5;
graphParser.RULE_contextDescription = 6;
graphParser.RULE_contextElementDescription = 7;
graphParser.RULE_externalFunctionDeclaration = 8;
graphParser.RULE_importDefinition = 9;
graphParser.RULE_typeDefinition = 10;
graphParser.RULE_functionDescription = 11;
graphParser.RULE_contextFunctionDescription = 12;
graphParser.RULE_blockDeclaration = 13;
graphParser.RULE_nodeDeclaration = 14;
graphParser.RULE_digressionDeclaration = 15;
graphParser.RULE_digressionContent = 16;
graphParser.RULE_comment = 17;
graphParser.RULE_nodeContent = 18;
graphParser.RULE_nodeOnExitSection = 19;
graphParser.RULE_nodeOnExitSectionItem = 20;
graphParser.RULE_onExitDoWithConfident = 21;
graphParser.RULE_nodeOnDigressionReturnSection = 22;
graphParser.RULE_nodeTransitionSection = 23;
graphParser.RULE_namedTransitionDefinition = 24;
graphParser.RULE_transitionDefinition = 25;
graphParser.RULE_transitionDefinitionOnMessage = 26;
graphParser.RULE_timeoutTransitionDefinition = 27;
graphParser.RULE_conditionalTransitionDefinition = 28;
graphParser.RULE_unconditionalTransitionDefinition = 29;
graphParser.RULE_doSection = 30;
graphParser.RULE_blockBody = 31;
graphParser.RULE_oneOrGroupedCommand = 32;
graphParser.RULE_nodeCommand = 33;
graphParser.RULE_cycleCommand = 34;
graphParser.RULE_statement = 35;
graphParser.RULE_statementBody = 36;
graphParser.RULE_logExpressionTypeCommand = 37;
graphParser.RULE_digressionControl = 38;
graphParser.RULE_digressionSpec = 39;
graphParser.RULE_exitCommand = 40;
graphParser.RULE_returnCommand = 41;
graphParser.RULE_variableDefinition = 42;
graphParser.RULE_variableDeclaration = 43;
graphParser.RULE_typeDescription = 44;
graphParser.RULE_conditionalCommand = 45;
graphParser.RULE_foreachCommand = 46;
graphParser.RULE_waitCommand = 47;
graphParser.RULE_activatedTransitionList = 48;
graphParser.RULE_gotoCommand = 49;
graphParser.RULE_setCommand = 50;
graphParser.RULE_assignOpCommand = 51;
graphParser.RULE_lValue = 52;
graphParser.RULE_expression = 53;
graphParser.RULE_objectPropertyExpressionPart = 54;
graphParser.RULE_externalFunctionCall = 55;
graphParser.RULE_blockFunctionCall = 56;
graphParser.RULE_slotFillingCall = 57;
graphParser.RULE_builtinFunctionCall = 58;
graphParser.RULE_argumentInvokation = 59;
graphParser.RULE_argumentList = 60;
graphParser.RULE_namedArgumentList = 61;
graphParser.RULE_positionalArgumentList = 62;
graphParser.RULE_positionalArgument = 63;
graphParser.RULE_namedArgument = 64;
graphParser.RULE_parameterList = 65;
graphParser.RULE_parameter = 66;
graphParser.RULE_reference = 67;
graphParser.RULE_nodeId = 68;
graphParser.RULE_blockId = 69;
graphParser.RULE_digressionId = 70;
graphParser.RULE_transitionName = 71;
graphParser.RULE_referenceName = 72;
graphParser.RULE_memberName = 73;
graphParser.RULE_tagName = 74;
graphParser.RULE_factName = 75;
graphParser.RULE_fieldName = 76;
graphParser.RULE_variableName = 77;
graphParser.RULE_argumentName = 78;
graphParser.RULE_parameterName = 79;
graphParser.RULE_propertyName = 80;
graphParser.RULE_builtinFunctionName = 81;
graphParser.RULE_externalFunctionName = 82;
graphParser.RULE_functionName = 83;
graphParser.RULE_anyNumber = 84;
graphParser.RULE_typeEof = 85;
graphParser.RULE_type_ = 86;
graphParser.RULE_questionMarkOptional = 87;
graphParser.RULE_objectTypeElementSeparator = 88;
graphParser.RULE_nonNullableType = 89;
graphParser.RULE_predefinedType = 90;
graphParser.RULE_propertySignature = 91;
graphParser.RULE_indexSignature = 92;
graphParser.RULE_typeAnnotation = 93;
graphParser.RULE_typeAlias = 94;

class FileContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_file;
        this.mainBlock = null;
    }

	EOF() {
	    return this.getToken(graphParser.EOF, 0);
	};

	blockContent() {
	    return this.getTypedRuleContext(BlockContentContext,0);
	};

	LIBRARY() {
	    return this.getToken(graphParser.LIBRARY, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFile(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFile(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFile(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ReferenceFileContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_referenceFile;
    }

	reference() {
	    return this.getTypedRuleContext(ReferenceContext,0);
	};

	EOF() {
	    return this.getToken(graphParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterReferenceFile(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitReferenceFile(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitReferenceFile(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExpressionFileContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_expressionFile;
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	EOF() {
	    return this.getToken(graphParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExpressionFile(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExpressionFile(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExpressionFile(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockContentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockContent;
        this._importDefinition = null;
        this.imports = [];
        this._typeDefinition = null;
        this.types = [];
        this.contextType = null;
        this.system = null;
        this._externalFunctionDeclaration = null;
        this.externalFunctions = [];
        this._nodeDeclaration = null;
        this.nodes = [];
        this._blockDeclaration = null;
        this.blocks = [];
        this._digressionDeclaration = null;
        this.digressions = [];
        this._contextFunctionDescription = null;
        this.contextFunctions = [];
    }

	CONTEXT() {
	    return this.getToken(graphParser.CONTEXT, 0);
	};

	SYSTEM() {
	    return this.getToken(graphParser.SYSTEM, 0);
	};

	importDefinition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ImportDefinitionContext);
	    } else {
	        return this.getTypedRuleContext(ImportDefinitionContext,i);
	    }
	};

	typeDefinition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TypeDefinitionContext);
	    } else {
	        return this.getTypedRuleContext(TypeDefinitionContext,i);
	    }
	};

	contextDescription() {
	    return this.getTypedRuleContext(ContextDescriptionContext,0);
	};

	systemDescription() {
	    return this.getTypedRuleContext(SystemDescriptionContext,0);
	};

	externalFunctionDeclaration = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExternalFunctionDeclarationContext);
	    } else {
	        return this.getTypedRuleContext(ExternalFunctionDeclarationContext,i);
	    }
	};

	nodeDeclaration = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NodeDeclarationContext);
	    } else {
	        return this.getTypedRuleContext(NodeDeclarationContext,i);
	    }
	};

	blockDeclaration = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(BlockDeclarationContext);
	    } else {
	        return this.getTypedRuleContext(BlockDeclarationContext,i);
	    }
	};

	digressionDeclaration = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(DigressionDeclarationContext);
	    } else {
	        return this.getTypedRuleContext(DigressionDeclarationContext,i);
	    }
	};

	contextFunctionDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ContextFunctionDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(ContextFunctionDescriptionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockContent(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockContent(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockContent(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SystemDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_systemDescription;
        this._systemElementDescription = null;
        this.elements = [];
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	objectTypeElementSeparator = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ObjectTypeElementSeparatorContext);
	    } else {
	        return this.getTypedRuleContext(ObjectTypeElementSeparatorContext,i);
	    }
	};

	systemElementDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(SystemElementDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(SystemElementDescriptionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSystemDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSystemDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSystemDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SystemElementDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_systemElementDescription;
        this.name = null;
        this.value = null;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	variableName() {
	    return this.getTypedRuleContext(VariableNameContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSystemElementDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSystemElementDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSystemElementDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ContextDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_contextDescription;
        this._contextElementDescription = null;
        this.elements = [];
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	objectTypeElementSeparator = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ObjectTypeElementSeparatorContext);
	    } else {
	        return this.getTypedRuleContext(ObjectTypeElementSeparatorContext,i);
	    }
	};

	contextElementDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ContextElementDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(ContextElementDescriptionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterContextDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitContextDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitContextDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ContextElementDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_contextElementDescription;
        this.input = null;
        this.output = null;
        this.name = null;
        this.type = null;
        this.value = null;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	variableName() {
	    return this.getTypedRuleContext(VariableNameContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	INPUT() {
	    return this.getToken(graphParser.INPUT, 0);
	};

	OUTPUT() {
	    return this.getToken(graphParser.OUTPUT, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterContextElementDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitContextElementDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitContextElementDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExternalFunctionDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_externalFunctionDeclaration;
        this.name = null;
        this.parameters = null;
        this.returnType = null;
    }

	EXTERNAL() {
	    return this.getToken(graphParser.EXTERNAL, 0);
	};

	FUNCTION() {
	    return this.getToken(graphParser.FUNCTION, 0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	externalFunctionName() {
	    return this.getTypedRuleContext(ExternalFunctionNameContext,0);
	};

	parameterList() {
	    return this.getTypedRuleContext(ParameterListContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExternalFunctionDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExternalFunctionDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExternalFunctionDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ImportDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_importDefinition;
        this.path = null;
        this.packageName = null;
    }

	IMPORT() {
	    return this.getToken(graphParser.IMPORT, 0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	StringLiteral = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.StringLiteral);
	    } else {
	        return this.getToken(graphParser.StringLiteral, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterImportDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitImportDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitImportDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeDefinition;
        this._functionDescription = null;
        this.functions = [];
    }

	TYPE() {
	    return this.getToken(graphParser.TYPE, 0);
	};

	typeAlias() {
	    return this.getTypedRuleContext(TypeAliasContext,0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	WITH() {
	    return this.getToken(graphParser.WITH, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	functionDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(FunctionDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(FunctionDescriptionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FunctionDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_functionDescription;
        this._LineComment = null;
        this.comments = [];
        this.doc = null;
        this.name = null;
        this.parameters = null;
        this.returnType = null;
        this.body = null;
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	functionName() {
	    return this.getTypedRuleContext(FunctionNameContext,0);
	};

	parameterList() {
	    return this.getTypedRuleContext(ParameterListContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	DocsComment() {
	    return this.getToken(graphParser.DocsComment, 0);
	};

	BlockComment = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.BlockComment);
	    } else {
	        return this.getToken(graphParser.BlockComment, i);
	    }
	};


	LineComment = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.LineComment);
	    } else {
	        return this.getToken(graphParser.LineComment, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFunctionDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFunctionDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFunctionDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ContextFunctionDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_contextFunctionDescription;
        this._LineComment = null;
        this.comments = [];
        this.doc = null;
        this.name = null;
        this.parameters = null;
        this.returnType = null;
        this.body = null;
    }

	FUNCTION() {
	    return this.getToken(graphParser.FUNCTION, 0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	functionName() {
	    return this.getTypedRuleContext(FunctionNameContext,0);
	};

	parameterList() {
	    return this.getTypedRuleContext(ParameterListContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	DocsComment() {
	    return this.getToken(graphParser.DocsComment, 0);
	};

	BlockComment = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.BlockComment);
	    } else {
	        return this.getToken(graphParser.BlockComment, i);
	    }
	};


	LineComment = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.LineComment);
	    } else {
	        return this.getToken(graphParser.LineComment, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterContextFunctionDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitContextFunctionDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitContextFunctionDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockDeclaration;
        this.sync = null;
        this.returnType = null;
        this.async = null;
        this.content = null;
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	blockContent() {
	    return this.getTypedRuleContext(BlockContentContext,0);
	};

	BLOCK() {
	    return this.getToken(graphParser.BLOCK, 0);
	};

	blockId() {
	    return this.getTypedRuleContext(BlockIdContext,0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	parameterList() {
	    return this.getTypedRuleContext(ParameterListContext,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	SYNC() {
	    return this.getToken(graphParser.SYNC, 0);
	};

	ASYNC() {
	    return this.getToken(graphParser.ASYNC, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeDeclaration;
        this.start = null;
        this.name = null;
        this.content = null;
    }

	NODE() {
	    return this.getToken(graphParser.NODE, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	nodeId() {
	    return this.getTypedRuleContext(NodeIdContext,0);
	};

	nodeContent() {
	    return this.getTypedRuleContext(NodeContentContext,0);
	};

	comment() {
	    return this.getTypedRuleContext(CommentContext,0);
	};

	START() {
	    return this.getToken(graphParser.START, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DigressionDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionDeclaration;
        this.global = null;
        this.preprocessor = null;
        this.name = null;
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	digressionContent() {
	    return this.getTypedRuleContext(DigressionContentContext,0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	digressionId() {
	    return this.getTypedRuleContext(DigressionIdContext,0);
	};

	GLOBAL() {
	    return this.getToken(graphParser.GLOBAL, 0);
	};

	PREPROCESSOR() {
	    return this.getToken(graphParser.PREPROCESSOR, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DigressionContentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionContent;
        this._transitionDefinitionOnMessage = null;
        this.transitions = [];
        this._variableDefinition = null;
        this.sharedState = [];
        this.copiedState = [];
    }

	CONDITIONS() {
	    return this.getToken(graphParser.CONDITIONS, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	nodeContent() {
	    return this.getTypedRuleContext(NodeContentContext,0);
	};

	SEMICOLON = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.SEMICOLON);
	    } else {
	        return this.getToken(graphParser.SEMICOLON, i);
	    }
	};


	transitionDefinitionOnMessage = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TransitionDefinitionOnMessageContext);
	    } else {
	        return this.getTypedRuleContext(TransitionDefinitionOnMessageContext,i);
	    }
	};

	SHARED = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.SHARED);
	    } else {
	        return this.getToken(graphParser.SHARED, i);
	    }
	};


	variableDefinition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VariableDefinitionContext);
	    } else {
	        return this.getTypedRuleContext(VariableDefinitionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionContent(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionContent(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionContent(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class CommentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_comment;
    }

	COMMENT() {
	    return this.getToken(graphParser.COMMENT, 0);
	};

	StringLiteral() {
	    return this.getToken(graphParser.StringLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterComment(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitComment(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitComment(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeContentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeContent;
        this.doSec = null;
        this.transitions = null;
        this.preExit = null;
        this.onExit = null;
    }

	doSection = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(DoSectionContext);
	    } else {
	        return this.getTypedRuleContext(DoSectionContext,i);
	    }
	};

	PREPROCESSOR() {
	    return this.getToken(graphParser.PREPROCESSOR, 0);
	};

	nodeOnDigressionReturnSection() {
	    return this.getTypedRuleContext(NodeOnDigressionReturnSectionContext,0);
	};

	nodeTransitionSection() {
	    return this.getTypedRuleContext(NodeTransitionSectionContext,0);
	};

	nodeOnExitSection() {
	    return this.getTypedRuleContext(NodeOnExitSectionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeContent(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeContent(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeContent(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeOnExitSectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeOnExitSection;
        this.defaultDo = null;
        this.digressionDo = null;
    }

	ONEXIT() {
	    return this.getToken(graphParser.ONEXIT, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	nodeOnExitSectionItem = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NodeOnExitSectionItemContext);
	    } else {
	        return this.getTypedRuleContext(NodeOnExitSectionItemContext,i);
	    }
	};

	DEFAULT() {
	    return this.getToken(graphParser.DEFAULT, 0);
	};

	COLON = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COLON);
	    } else {
	        return this.getToken(graphParser.COLON, i);
	    }
	};


	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	onExitDoWithConfident = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(OnExitDoWithConfidentContext);
	    } else {
	        return this.getTypedRuleContext(OnExitDoWithConfidentContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeOnExitSection(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeOnExitSection(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeOnExitSection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeOnExitSectionItemContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeOnExitSectionItem;
    }

	onExitDoWithConfident() {
	    return this.getTypedRuleContext(OnExitDoWithConfidentContext,0);
	};

	transitionName = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TransitionNameContext);
	    } else {
	        return this.getTypedRuleContext(TransitionNameContext,i);
	    }
	};

	COLON = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COLON);
	    } else {
	        return this.getToken(graphParser.COLON, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeOnExitSectionItem(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeOnExitSectionItem(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeOnExitSectionItem(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class OnExitDoWithConfidentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_onExitDoWithConfident;
        this.doSec = null;
        this.confidentDo = null;
    }

	doSection = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(DoSectionContext);
	    } else {
	        return this.getTypedRuleContext(DoSectionContext,i);
	    }
	};

	ON() {
	    return this.getToken(graphParser.ON, 0);
	};

	CONFIDENT() {
	    return this.getToken(graphParser.CONFIDENT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterOnExitDoWithConfident(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitOnExitDoWithConfident(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitOnExitDoWithConfident(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeOnDigressionReturnSectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeOnDigressionReturnSection;
    }

	ONDIGRETURN() {
	    return this.getToken(graphParser.ONDIGRETURN, 0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeOnDigressionReturnSection(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeOnDigressionReturnSection(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeOnDigressionReturnSection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeTransitionSectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeTransitionSection;
        this._namedTransitionDefinition = null;
        this.list = [];
    }

	TRANSITIONS() {
	    return this.getToken(graphParser.TRANSITIONS, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	namedTransitionDefinition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NamedTransitionDefinitionContext);
	    } else {
	        return this.getTypedRuleContext(NamedTransitionDefinitionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeTransitionSection(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeTransitionSection(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeTransitionSection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NamedTransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_namedTransitionDefinition;
        this.target = null;
    }

	transitionName() {
	    return this.getTypedRuleContext(TransitionNameContext,0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	GOTO() {
	    return this.getToken(graphParser.GOTO, 0);
	};

	transitionDefinition() {
	    return this.getTypedRuleContext(TransitionDefinitionContext,0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	nodeId() {
	    return this.getTypedRuleContext(NodeIdContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNamedTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNamedTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNamedTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_transitionDefinition;
    }

	transitionDefinitionOnMessage() {
	    return this.getTypedRuleContext(TransitionDefinitionOnMessageContext,0);
	};

	unconditionalTransitionDefinition() {
	    return this.getTypedRuleContext(UnconditionalTransitionDefinitionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TransitionDefinitionOnMessageContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_transitionDefinitionOnMessage;
    }

	conditionalTransitionDefinition() {
	    return this.getTypedRuleContext(ConditionalTransitionDefinitionContext,0);
	};

	timeoutTransitionDefinition() {
	    return this.getTypedRuleContext(TimeoutTransitionDefinitionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTransitionDefinitionOnMessage(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTransitionDefinitionOnMessage(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTransitionDefinitionOnMessage(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TimeoutTransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_timeoutTransitionDefinition;
        this.timeout = null;
    }

	ON() {
	    return this.getToken(graphParser.ON, 0);
	};

	TIMEOUT() {
	    return this.getToken(graphParser.TIMEOUT, 0);
	};

	anyNumber() {
	    return this.getTypedRuleContext(AnyNumberContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTimeoutTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTimeoutTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTimeoutTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ConditionalTransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_conditionalTransitionDefinition;
        this.condition = null;
        this.priority = null;
        this.confident = null;
        this._tagName = null;
        this.tags = [];
    }

	ON() {
	    return this.getToken(graphParser.ON, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	PRIORITY() {
	    return this.getToken(graphParser.PRIORITY, 0);
	};

	WHEN() {
	    return this.getToken(graphParser.WHEN, 0);
	};

	TAGS() {
	    return this.getToken(graphParser.TAGS, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	CONFIDENT() {
	    return this.getToken(graphParser.CONFIDENT, 0);
	};

	tagName = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TagNameContext);
	    } else {
	        return this.getTypedRuleContext(TagNameContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterConditionalTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitConditionalTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitConditionalTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class UnconditionalTransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_unconditionalTransitionDefinition;
    }


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterUnconditionalTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitUnconditionalTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitUnconditionalTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DoSectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_doSection;
    }

	DO() {
	    return this.getToken(graphParser.DO, 0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDoSection(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDoSection(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDoSection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockBodyContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockBody;
        this._oneOrGroupedCommand = null;
        this.commands = [];
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	oneOrGroupedCommand = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(OneOrGroupedCommandContext);
	    } else {
	        return this.getTypedRuleContext(OneOrGroupedCommandContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockBody(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockBody(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockBody(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class OneOrGroupedCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_oneOrGroupedCommand;
    }

	nodeCommand() {
	    return this.getTypedRuleContext(NodeCommandContext,0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterOneOrGroupedCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitOneOrGroupedCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitOneOrGroupedCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeCommand;
    }

	statement() {
	    return this.getTypedRuleContext(StatementContext,0);
	};

	conditionalCommand() {
	    return this.getTypedRuleContext(ConditionalCommandContext,0);
	};

	cycleCommand() {
	    return this.getTypedRuleContext(CycleCommandContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class CycleCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_cycleCommand;
    }

	foreachCommand() {
	    return this.getTypedRuleContext(ForeachCommandContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterCycleCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitCycleCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitCycleCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class StatementContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_statement;
    }

	statementBody() {
	    return this.getTypedRuleContext(StatementBodyContext,0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStatement(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStatement(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStatement(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class StatementBodyContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_statementBody;
    }

	waitCommand() {
	    return this.getTypedRuleContext(WaitCommandContext,0);
	};

	gotoCommand() {
	    return this.getTypedRuleContext(GotoCommandContext,0);
	};

	setCommand() {
	    return this.getTypedRuleContext(SetCommandContext,0);
	};

	returnCommand() {
	    return this.getTypedRuleContext(ReturnCommandContext,0);
	};

	exitCommand() {
	    return this.getTypedRuleContext(ExitCommandContext,0);
	};

	assignOpCommand() {
	    return this.getTypedRuleContext(AssignOpCommandContext,0);
	};

	variableDefinition() {
	    return this.getTypedRuleContext(VariableDefinitionContext,0);
	};

	logExpressionTypeCommand() {
	    return this.getTypedRuleContext(LogExpressionTypeCommandContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStatementBody(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStatementBody(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStatementBody(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class LogExpressionTypeCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_logExpressionTypeCommand;
    }

	LOGEXPR() {
	    return this.getToken(graphParser.LOGEXPR, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLogExpressionTypeCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLogExpressionTypeCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLogExpressionTypeCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DigressionControlContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionControl;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class SaveDigressionContext extends DigressionControlContext {

    constructor(parser, ctx) {
        super(parser);
        this.spec = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	SAVE() {
	    return this.getToken(graphParser.SAVE, 0);
	};

	digressionSpec() {
	    return this.getTypedRuleContext(DigressionSpecContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSaveDigression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSaveDigression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSaveDigression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.SaveDigressionContext = SaveDigressionContext;

class ApplyDigressionContext extends DigressionControlContext {

    constructor(parser, ctx) {
        super(parser);
        this.mask = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	APPLY() {
	    return this.getToken(graphParser.APPLY, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterApplyDigression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitApplyDigression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitApplyDigression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ApplyDigressionContext = ApplyDigressionContext;

class EnableDigressionContext extends DigressionControlContext {

    constructor(parser, ctx) {
        super(parser);
        this.spec = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	ENABLE() {
	    return this.getToken(graphParser.ENABLE, 0);
	};

	digressionSpec() {
	    return this.getTypedRuleContext(DigressionSpecContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterEnableDigression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitEnableDigression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitEnableDigression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.EnableDigressionContext = EnableDigressionContext;

class DisableDigressionContext extends DigressionControlContext {

    constructor(parser, ctx) {
        super(parser);
        this.spec = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	DISABLE() {
	    return this.getToken(graphParser.DISABLE, 0);
	};

	digressionSpec() {
	    return this.getTypedRuleContext(DigressionSpecContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDisableDigression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDisableDigression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDisableDigression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.DisableDigressionContext = DisableDigressionContext;

class DigressionSpecContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionSpec;
        this._digressionId = null;
        this.list = [];
    }

	digressionId = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(DigressionIdContext);
	    } else {
	        return this.getTypedRuleContext(DigressionIdContext,i);
	    }
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionSpec(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionSpec(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionSpec(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExitCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_exitCommand;
    }

	EXIT() {
	    return this.getToken(graphParser.EXIT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExitCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExitCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExitCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ReturnCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_returnCommand;
        this.returnValue = null;
    }

	RETURN() {
	    return this.getToken(graphParser.RETURN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterReturnCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitReturnCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitReturnCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class VariableDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_variableDefinition;
        this.type = null;
        this.value = null;
    }

	variableDeclaration() {
	    return this.getTypedRuleContext(VariableDeclarationContext,0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterVariableDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitVariableDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitVariableDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class VariableDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_variableDeclaration;
    }

	VAR() {
	    return this.getToken(graphParser.VAR, 0);
	};

	variableName() {
	    return this.getTypedRuleContext(VariableNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterVariableDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitVariableDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitVariableDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeDescription;
    }

	type_() {
	    return this.getTypedRuleContext(Type_Context,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ConditionalCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_conditionalCommand;
        this.condition = null;
        this.thenCommand = null;
        this.elseCommand = null;
    }

	IF() {
	    return this.getToken(graphParser.IF, 0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	oneOrGroupedCommand = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(OneOrGroupedCommandContext);
	    } else {
	        return this.getTypedRuleContext(OneOrGroupedCommandContext,i);
	    }
	};

	ELSE() {
	    return this.getToken(graphParser.ELSE, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterConditionalCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitConditionalCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitConditionalCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ForeachCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_foreachCommand;
        this.container = null;
        this.body = null;
    }

	FOR() {
	    return this.getToken(graphParser.FOR, 0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	variableDeclaration() {
	    return this.getTypedRuleContext(VariableDeclarationContext,0);
	};

	IN() {
	    return this.getToken(graphParser.IN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	oneOrGroupedCommand() {
	    return this.getTypedRuleContext(OneOrGroupedCommandContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterForeachCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitForeachCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitForeachCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class WaitCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_waitCommand;
    }

	WAIT() {
	    return this.getToken(graphParser.WAIT, 0);
	};

	activatedTransitionList() {
	    return this.getTypedRuleContext(ActivatedTransitionListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterWaitCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitWaitCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitWaitCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ActivatedTransitionListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_activatedTransitionList;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class SelectedActivatedTransitionListContext extends ActivatedTransitionListContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	transitionName = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TransitionNameContext);
	    } else {
	        return this.getTypedRuleContext(TransitionNameContext,i);
	    }
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSelectedActivatedTransitionList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSelectedActivatedTransitionList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSelectedActivatedTransitionList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.SelectedActivatedTransitionListContext = SelectedActivatedTransitionListContext;

class AllActivatedTransitionListContext extends ActivatedTransitionListContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	STAR() {
	    return this.getToken(graphParser.STAR, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterAllActivatedTransitionList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitAllActivatedTransitionList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitAllActivatedTransitionList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.AllActivatedTransitionListContext = AllActivatedTransitionListContext;

class GotoCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_gotoCommand;
    }

	GOTO() {
	    return this.getToken(graphParser.GOTO, 0);
	};

	transitionName() {
	    return this.getTypedRuleContext(TransitionNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterGotoCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitGotoCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitGotoCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SetCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_setCommand;
        this.ref = null;
        this.rightPartExpression = null;
    }

	SET() {
	    return this.getToken(graphParser.SET, 0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSetCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSetCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSetCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class AssignOpCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_assignOpCommand;
        this.ref = null;
        this.op = null;
        this.rightPartExpression = null;
    }

	SET() {
	    return this.getToken(graphParser.SET, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	AddOp() {
	    return this.getToken(graphParser.AddOp, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	RemoveOp() {
	    return this.getToken(graphParser.RemoveOp, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterAssignOpCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitAssignOpCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitAssignOpCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class LValueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_lValue;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class LValueReferenceExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	reference() {
	    return this.getTypedRuleContext(ReferenceContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueReferenceExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueReferenceExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueReferenceExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueReferenceExpressionContext = LValueReferenceExpressionContext;

class LValueParenthesizedExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueParenthesizedExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueParenthesizedExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueParenthesizedExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueParenthesizedExpressionContext = LValueParenthesizedExpressionContext;

class LValueDotAccessExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        super.copyFrom(ctx);
    }

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueDotAccessExpressionContext = LValueDotAccessExpressionContext;

class LValueLiteralIndexAccessExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.index = null;;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueLiteralIndexAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueLiteralIndexAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueLiteralIndexAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueLiteralIndexAccessExpressionContext = LValueLiteralIndexAccessExpressionContext;

class LValueExpressionIndexAccessExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.index = null;;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueExpressionIndexAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueExpressionIndexAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueExpressionIndexAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueExpressionIndexAccessExpressionContext = LValueExpressionIndexAccessExpressionContext;

class ExpressionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_expression;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class TernaryOpExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.condition = null;;
        this.thenExpression = null;;
        this.elseExpression = null;;
        super.copyFrom(ctx);
    }

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTernaryOpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTernaryOpExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTernaryOpExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.TernaryOpExpressionContext = TernaryOpExpressionContext;

class ExpressionIndexAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.index = null;;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExpressionIndexAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExpressionIndexAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExpressionIndexAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ExpressionIndexAccessExpressionContext = ExpressionIndexAccessExpressionContext;

class NullCollateExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.nullable = null;;
        this.defaultValue = null;;
        super.copyFrom(ctx);
    }

	QMARK = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.QMARK);
	    } else {
	        return this.getToken(graphParser.QMARK, i);
	    }
	};


	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNullCollateExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNullCollateExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNullCollateExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NullCollateExpressionContext = NullCollateExpressionContext;

class BooleanLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	BooleanLiteral() {
	    return this.getToken(graphParser.BooleanLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBooleanLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBooleanLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBooleanLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BooleanLiteralExpressionContext = BooleanLiteralExpressionContext;

class NumberLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	anyNumber() {
	    return this.getTypedRuleContext(AnyNumberContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNumberLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNumberLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNumberLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NumberLiteralExpressionContext = NumberLiteralExpressionContext;

class BinaryOpExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.left = null;;
        this.op = null;;
        this.right = null;;
        super.copyFrom(ctx);
    }

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	STAR() {
	    return this.getToken(graphParser.STAR, 0);
	};

	SLASH() {
	    return this.getToken(graphParser.SLASH, 0);
	};

	PERCENT() {
	    return this.getToken(graphParser.PERCENT, 0);
	};

	PLUS() {
	    return this.getToken(graphParser.PLUS, 0);
	};

	MINUS() {
	    return this.getToken(graphParser.MINUS, 0);
	};

	LANGLE() {
	    return this.getToken(graphParser.LANGLE, 0);
	};

	LessEqualOp() {
	    return this.getToken(graphParser.LessEqualOp, 0);
	};

	RANGLE() {
	    return this.getToken(graphParser.RANGLE, 0);
	};

	GreaterEqualOp() {
	    return this.getToken(graphParser.GreaterEqualOp, 0);
	};

	EqualOp() {
	    return this.getToken(graphParser.EqualOp, 0);
	};

	NotEqualOp() {
	    return this.getToken(graphParser.NotEqualOp, 0);
	};

	AndOp() {
	    return this.getToken(graphParser.AndOp, 0);
	};

	OrOp() {
	    return this.getToken(graphParser.OrOp, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBinaryOpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBinaryOpExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBinaryOpExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BinaryOpExpressionContext = BinaryOpExpressionContext;

class OptionalCallDotAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        this.arguments = null;;
        super.copyFrom(ctx);
    }

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterOptionalCallDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitOptionalCallDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitOptionalCallDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.OptionalCallDotAccessExpressionContext = OptionalCallDotAccessExpressionContext;

class CastExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	AS() {
	    return this.getToken(graphParser.AS, 0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterCastExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitCastExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitCastExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.CastExpressionContext = CastExpressionContext;

class BuiltinFunctionCallExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	builtinFunctionCall() {
	    return this.getTypedRuleContext(BuiltinFunctionCallContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBuiltinFunctionCallExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBuiltinFunctionCallExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBuiltinFunctionCallExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BuiltinFunctionCallExpressionContext = BuiltinFunctionCallExpressionContext;

class ParenthesizedExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParenthesizedExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParenthesizedExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParenthesizedExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ParenthesizedExpressionContext = ParenthesizedExpressionContext;

class CallDotAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        this.arguments = null;;
        super.copyFrom(ctx);
    }

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterCallDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitCallDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitCallDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.CallDotAccessExpressionContext = CallDotAccessExpressionContext;

class NullLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	NULL() {
	    return this.getToken(graphParser.NULL, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNullLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNullLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNullLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NullLiteralExpressionContext = NullLiteralExpressionContext;

class IsNullExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	IS() {
	    return this.getToken(graphParser.IS, 0);
	};

	NULL() {
	    return this.getToken(graphParser.NULL, 0);
	};

	NOT() {
	    return this.getToken(graphParser.NOT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterIsNullExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitIsNullExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitIsNullExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.IsNullExpressionContext = IsNullExpressionContext;

class DigressionControlExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	digressionControl() {
	    return this.getTypedRuleContext(DigressionControlContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionControlExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionControlExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionControlExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.DigressionControlExpressionContext = DigressionControlExpressionContext;

class TupleLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this._expression = null;;
        this.elements = [];;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTupleLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTupleLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTupleLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.TupleLiteralExpressionContext = TupleLiteralExpressionContext;

class BlockFunctionCallExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	blockFunctionCall() {
	    return this.getTypedRuleContext(BlockFunctionCallContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockFunctionCallExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockFunctionCallExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockFunctionCallExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BlockFunctionCallExpressionContext = BlockFunctionCallExpressionContext;

class ReferenceExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	reference() {
	    return this.getTypedRuleContext(ReferenceContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterReferenceExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitReferenceExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitReferenceExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ReferenceExpressionContext = ReferenceExpressionContext;

class OptionalDotAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        super.copyFrom(ctx);
    }

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterOptionalDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitOptionalDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitOptionalDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.OptionalDotAccessExpressionContext = OptionalDotAccessExpressionContext;

class ObjectLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this._objectPropertyExpressionPart = null;;
        this.fields = [];;
        super.copyFrom(ctx);
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	objectPropertyExpressionPart = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ObjectPropertyExpressionPartContext);
	    } else {
	        return this.getTypedRuleContext(ObjectPropertyExpressionPartContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterObjectLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitObjectLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitObjectLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ObjectLiteralExpressionContext = ObjectLiteralExpressionContext;

class LiteralIndexAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.index = null;;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLiteralIndexAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLiteralIndexAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLiteralIndexAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LiteralIndexAccessExpressionContext = LiteralIndexAccessExpressionContext;

class ExternFunctionCallExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	externalFunctionCall() {
	    return this.getTypedRuleContext(ExternalFunctionCallContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExternFunctionCallExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExternFunctionCallExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExternFunctionCallExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ExternFunctionCallExpressionContext = ExternFunctionCallExpressionContext;

class StringLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	StringLiteral() {
	    return this.getToken(graphParser.StringLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStringLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStringLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStringLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.StringLiteralExpressionContext = StringLiteralExpressionContext;

class SlotFillingCallExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	slotFillingCall() {
	    return this.getTypedRuleContext(SlotFillingCallContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSlotFillingCallExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSlotFillingCallExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSlotFillingCallExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.SlotFillingCallExpressionContext = SlotFillingCallExpressionContext;

class IsDefinedExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	reference() {
	    return this.getTypedRuleContext(ReferenceContext,0);
	};

	IS() {
	    return this.getToken(graphParser.IS, 0);
	};

	DEFINED() {
	    return this.getToken(graphParser.DEFINED, 0);
	};

	NOT() {
	    return this.getToken(graphParser.NOT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterIsDefinedExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitIsDefinedExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitIsDefinedExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.IsDefinedExpressionContext = IsDefinedExpressionContext;

class UnaryOpExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.op = null;;
        super.copyFrom(ctx);
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	PLUS() {
	    return this.getToken(graphParser.PLUS, 0);
	};

	MINUS() {
	    return this.getToken(graphParser.MINUS, 0);
	};

	NotOperator() {
	    return this.getToken(graphParser.NotOperator, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterUnaryOpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitUnaryOpExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitUnaryOpExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.UnaryOpExpressionContext = UnaryOpExpressionContext;

class DotAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        super.copyFrom(ctx);
    }

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.DotAccessExpressionContext = DotAccessExpressionContext;

class ObjectPropertyExpressionPartContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_objectPropertyExpressionPart;
        this.name = null;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	propertyName() {
	    return this.getTypedRuleContext(PropertyNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterObjectPropertyExpressionPart(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitObjectPropertyExpressionPart(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitObjectPropertyExpressionPart(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExternalFunctionCallContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_externalFunctionCall;
        this.name = null;
        this.arguments = null;
    }

	EXTERNAL() {
	    return this.getToken(graphParser.EXTERNAL, 0);
	};

	externalFunctionName() {
	    return this.getTypedRuleContext(ExternalFunctionNameContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExternalFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExternalFunctionCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExternalFunctionCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockFunctionCallContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockFunctionCall;
        this.name = null;
        this.arguments = null;
    }

	BLOCKCALL() {
	    return this.getToken(graphParser.BLOCKCALL, 0);
	};

	blockId() {
	    return this.getTypedRuleContext(BlockIdContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockFunctionCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockFunctionCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SlotFillingCallContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_slotFillingCall;
        this.name = null;
        this.arguments = null;
    }

	FILL() {
	    return this.getToken(graphParser.FILL, 0);
	};

	typeAlias() {
	    return this.getTypedRuleContext(TypeAliasContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSlotFillingCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSlotFillingCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSlotFillingCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BuiltinFunctionCallContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_builtinFunctionCall;
        this.name = null;
        this.arguments = null;
    }

	BuiltinMark() {
	    return this.getToken(graphParser.BuiltinMark, 0);
	};

	builtinFunctionName() {
	    return this.getTypedRuleContext(BuiltinFunctionNameContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBuiltinFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBuiltinFunctionCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBuiltinFunctionCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ArgumentInvokationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_argumentInvokation;
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	argumentList() {
	    return this.getTypedRuleContext(ArgumentListContext,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterArgumentInvokation(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitArgumentInvokation(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitArgumentInvokation(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ArgumentListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_argumentList;
        this.positional = null;
        this.named = null;
    }

	positionalArgumentList() {
	    return this.getTypedRuleContext(PositionalArgumentListContext,0);
	};

	COMMA() {
	    return this.getToken(graphParser.COMMA, 0);
	};

	namedArgumentList() {
	    return this.getTypedRuleContext(NamedArgumentListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterArgumentList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitArgumentList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitArgumentList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NamedArgumentListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_namedArgumentList;
        this._namedArgument = null;
        this.arguments = [];
    }

	namedArgument = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NamedArgumentContext);
	    } else {
	        return this.getTypedRuleContext(NamedArgumentContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNamedArgumentList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNamedArgumentList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNamedArgumentList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class PositionalArgumentListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_positionalArgumentList;
        this._positionalArgument = null;
        this.arguments = [];
    }

	positionalArgument = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(PositionalArgumentContext);
	    } else {
	        return this.getTypedRuleContext(PositionalArgumentContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPositionalArgumentList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPositionalArgumentList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPositionalArgumentList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class PositionalArgumentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_positionalArgument;
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPositionalArgument(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPositionalArgument(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPositionalArgument(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NamedArgumentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_namedArgument;
    }

	argumentName() {
	    return this.getTypedRuleContext(ArgumentNameContext,0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNamedArgument(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNamedArgument(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNamedArgument(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ParameterListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_parameterList;
        this._parameter = null;
        this.parameters = [];
    }

	parameter = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ParameterContext);
	    } else {
	        return this.getTypedRuleContext(ParameterContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParameterList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParameterList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParameterList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ParameterContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_parameter;
        this.name = null;
        this.type = null;
        this.defaultValue = null;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	parameterName() {
	    return this.getTypedRuleContext(ParameterNameContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParameter(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParameter(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParameter(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ReferenceContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_reference;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class LocalReferenceContext extends ReferenceContext {

    constructor(parser, ctx) {
        super(parser);
        this.refName = null;;
        super.copyFrom(ctx);
    }

	referenceName() {
	    return this.getTypedRuleContext(ReferenceNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLocalReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLocalReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLocalReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LocalReferenceContext = LocalReferenceContext;

class DigressionReferenceContext extends ReferenceContext {

    constructor(parser, ctx) {
        super(parser);
        this.name = null;;
        this.shared = null;;
        this.refName = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	PERIOD = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.PERIOD);
	    } else {
	        return this.getToken(graphParser.PERIOD, i);
	    }
	};


	digressionId() {
	    return this.getTypedRuleContext(DigressionIdContext,0);
	};

	SHARED() {
	    return this.getToken(graphParser.SHARED, 0);
	};

	referenceName() {
	    return this.getTypedRuleContext(ReferenceNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.DigressionReferenceContext = DigressionReferenceContext;

class ContextReferenceContext extends ReferenceContext {

    constructor(parser, ctx) {
        super(parser);
        this.refName = null;;
        super.copyFrom(ctx);
    }

	ContextMark() {
	    return this.getToken(graphParser.ContextMark, 0);
	};

	referenceName() {
	    return this.getTypedRuleContext(ReferenceNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterContextReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitContextReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitContextReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ContextReferenceContext = ContextReferenceContext;

class BuiltinReferenceContext extends ReferenceContext {

    constructor(parser, ctx) {
        super(parser);
        this.refName = null;;
        super.copyFrom(ctx);
    }

	BuiltinMark() {
	    return this.getToken(graphParser.BuiltinMark, 0);
	};

	referenceName() {
	    return this.getTypedRuleContext(ReferenceNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBuiltinReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBuiltinReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBuiltinReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BuiltinReferenceContext = BuiltinReferenceContext;

class NodeIdContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeId;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeId(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeId(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeId(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockIdContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockId;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockId(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockId(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockId(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DigressionIdContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionId;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionId(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionId(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionId(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TransitionNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_transitionName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTransitionName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTransitionName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTransitionName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ReferenceNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_referenceName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterReferenceName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitReferenceName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitReferenceName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class MemberNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_memberName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterMemberName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitMemberName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitMemberName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TagNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_tagName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTagName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTagName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTagName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FactNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_factName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFactName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFactName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFactName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FieldNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_fieldName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFieldName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFieldName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFieldName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class VariableNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_variableName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterVariableName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitVariableName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitVariableName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ArgumentNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_argumentName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterArgumentName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitArgumentName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitArgumentName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ParameterNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_parameterName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParameterName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParameterName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParameterName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class PropertyNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_propertyName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPropertyName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPropertyName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPropertyName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BuiltinFunctionNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_builtinFunctionName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBuiltinFunctionName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBuiltinFunctionName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBuiltinFunctionName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExternalFunctionNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_externalFunctionName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExternalFunctionName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExternalFunctionName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExternalFunctionName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FunctionNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_functionName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFunctionName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFunctionName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFunctionName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class AnyNumberContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_anyNumber;
    }

	DecimalLiteral() {
	    return this.getToken(graphParser.DecimalLiteral, 0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterAnyNumber(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitAnyNumber(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitAnyNumber(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeEofContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeEof;
    }

	type_() {
	    return this.getTypedRuleContext(Type_Context,0);
	};

	EOF() {
	    return this.getToken(graphParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeEof(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeEof(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeEof(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Type_Context extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_type_;
        this._nonNullableType = null;
        this.types = [];
        this._questionMarkOptional = null;
        this.nullableFlags = [];
    }

	nonNullableType = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NonNullableTypeContext);
	    } else {
	        return this.getTypedRuleContext(NonNullableTypeContext,i);
	    }
	};

	questionMarkOptional = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(QuestionMarkOptionalContext);
	    } else {
	        return this.getTypedRuleContext(QuestionMarkOptionalContext,i);
	    }
	};

	TypeOr = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.TypeOr);
	    } else {
	        return this.getToken(graphParser.TypeOr, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterType_(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitType_(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitType_(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class QuestionMarkOptionalContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_questionMarkOptional;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class PresentQuestionMarkContext extends QuestionMarkOptionalContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPresentQuestionMark(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPresentQuestionMark(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPresentQuestionMark(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.PresentQuestionMarkContext = PresentQuestionMarkContext;

class NoQuestionMarkContext extends QuestionMarkOptionalContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNoQuestionMark(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNoQuestionMark(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNoQuestionMark(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NoQuestionMarkContext = NoQuestionMarkContext;

class ObjectTypeElementSeparatorContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_objectTypeElementSeparator;
    }

	COMMA() {
	    return this.getToken(graphParser.COMMA, 0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterObjectTypeElementSeparator(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitObjectTypeElementSeparator(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitObjectTypeElementSeparator(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NonNullableTypeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nonNullableType;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class PredefinedTypeRefContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	predefinedType() {
	    return this.getTypedRuleContext(PredefinedTypeContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPredefinedTypeRef(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPredefinedTypeRef(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPredefinedTypeRef(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.PredefinedTypeRefContext = PredefinedTypeRefContext;

class ArrayTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this.nullable = null;;
        super.copyFrom(ctx);
    }

	nonNullableType() {
	    return this.getTypedRuleContext(NonNullableTypeContext,0);
	};

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterArrayType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitArrayType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitArrayType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ArrayTypeContext = ArrayTypeContext;

class StringLiteralTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this.value = null;;
        super.copyFrom(ctx);
    }

	StringLiteral() {
	    return this.getToken(graphParser.StringLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStringLiteralType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStringLiteralType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStringLiteralType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.StringLiteralTypeContext = StringLiteralTypeContext;

class TupleTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this._type_ = null;;
        this.elementTypes = [];;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	type_ = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Type_Context);
	    } else {
	        return this.getTypedRuleContext(Type_Context,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTupleType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTupleType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTupleType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.TupleTypeContext = TupleTypeContext;

class TypeReferenceContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	typeAlias() {
	    return this.getTypedRuleContext(TypeAliasContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.TypeReferenceContext = TypeReferenceContext;

class AngleUnionTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this._type_ = null;;
        this.types = [];;
        super.copyFrom(ctx);
    }

	LANGLE() {
	    return this.getToken(graphParser.LANGLE, 0);
	};

	RANGLE() {
	    return this.getToken(graphParser.RANGLE, 0);
	};

	TypeOr = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.TypeOr);
	    } else {
	        return this.getToken(graphParser.TypeOr, i);
	    }
	};


	type_ = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Type_Context);
	    } else {
	        return this.getTypedRuleContext(Type_Context,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterAngleUnionType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitAngleUnionType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitAngleUnionType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.AngleUnionTypeContext = AngleUnionTypeContext;

class ParenthesisedTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	type_() {
	    return this.getTypedRuleContext(Type_Context,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParenthesisedType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParenthesisedType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParenthesisedType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ParenthesisedTypeContext = ParenthesisedTypeContext;

class ObjectTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this._propertySignature = null;;
        this.properties = [];;
        super.copyFrom(ctx);
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	objectTypeElementSeparator = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ObjectTypeElementSeparatorContext);
	    } else {
	        return this.getTypedRuleContext(ObjectTypeElementSeparatorContext,i);
	    }
	};

	indexSignature() {
	    return this.getTypedRuleContext(IndexSignatureContext,0);
	};

	propertySignature = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(PropertySignatureContext);
	    } else {
	        return this.getTypedRuleContext(PropertySignatureContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterObjectType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitObjectType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitObjectType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ObjectTypeContext = ObjectTypeContext;

class PredefinedTypeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_predefinedType;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class NumberTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	Number() {
	    return this.getToken(graphParser.Number, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNumberType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNumberType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNumberType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NumberTypeContext = NumberTypeContext;

class BooleanTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	Boolean() {
	    return this.getToken(graphParser.Boolean, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBooleanType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBooleanType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBooleanType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BooleanTypeContext = BooleanTypeContext;

class StringTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	String() {
	    return this.getToken(graphParser.String, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStringType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStringType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStringType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.StringTypeContext = StringTypeContext;

class EmptyTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	Empty() {
	    return this.getToken(graphParser.Empty, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterEmptyType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitEmptyType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitEmptyType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.EmptyTypeContext = EmptyTypeContext;

class UnknownTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	Unknown() {
	    return this.getToken(graphParser.Unknown, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterUnknownType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitUnknownType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitUnknownType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.UnknownTypeContext = UnknownTypeContext;

class PropertySignatureContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_propertySignature;
    }

	propertyName() {
	    return this.getTypedRuleContext(PropertyNameContext,0);
	};

	typeAnnotation() {
	    return this.getTypedRuleContext(TypeAnnotationContext,0);
	};

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPropertySignature(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPropertySignature(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPropertySignature(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class IndexSignatureContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_indexSignature;
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	String() {
	    return this.getToken(graphParser.String, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	typeAnnotation() {
	    return this.getTypedRuleContext(TypeAnnotationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterIndexSignature(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitIndexSignature(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitIndexSignature(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeAnnotationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeAnnotation;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	type_() {
	    return this.getTypedRuleContext(Type_Context,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeAnnotation(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeAnnotation(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeAnnotation(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeAliasContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeAlias;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeAlias(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeAlias(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeAlias(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}




graphParser.FileContext = FileContext; 
graphParser.ReferenceFileContext = ReferenceFileContext; 
graphParser.ExpressionFileContext = ExpressionFileContext; 
graphParser.BlockContentContext = BlockContentContext; 
graphParser.SystemDescriptionContext = SystemDescriptionContext; 
graphParser.SystemElementDescriptionContext = SystemElementDescriptionContext; 
graphParser.ContextDescriptionContext = ContextDescriptionContext; 
graphParser.ContextElementDescriptionContext = ContextElementDescriptionContext; 
graphParser.ExternalFunctionDeclarationContext = ExternalFunctionDeclarationContext; 
graphParser.ImportDefinitionContext = ImportDefinitionContext; 
graphParser.TypeDefinitionContext = TypeDefinitionContext; 
graphParser.FunctionDescriptionContext = FunctionDescriptionContext; 
graphParser.ContextFunctionDescriptionContext = ContextFunctionDescriptionContext; 
graphParser.BlockDeclarationContext = BlockDeclarationContext; 
graphParser.NodeDeclarationContext = NodeDeclarationContext; 
graphParser.DigressionDeclarationContext = DigressionDeclarationContext; 
graphParser.DigressionContentContext = DigressionContentContext; 
graphParser.CommentContext = CommentContext; 
graphParser.NodeContentContext = NodeContentContext; 
graphParser.NodeOnExitSectionContext = NodeOnExitSectionContext; 
graphParser.NodeOnExitSectionItemContext = NodeOnExitSectionItemContext; 
graphParser.OnExitDoWithConfidentContext = OnExitDoWithConfidentContext; 
graphParser.NodeOnDigressionReturnSectionContext = NodeOnDigressionReturnSectionContext; 
graphParser.NodeTransitionSectionContext = NodeTransitionSectionContext; 
graphParser.NamedTransitionDefinitionContext = NamedTransitionDefinitionContext; 
graphParser.TransitionDefinitionContext = TransitionDefinitionContext; 
graphParser.TransitionDefinitionOnMessageContext = TransitionDefinitionOnMessageContext; 
graphParser.TimeoutTransitionDefinitionContext = TimeoutTransitionDefinitionContext; 
graphParser.ConditionalTransitionDefinitionContext = ConditionalTransitionDefinitionContext; 
graphParser.UnconditionalTransitionDefinitionContext = UnconditionalTransitionDefinitionContext; 
graphParser.DoSectionContext = DoSectionContext; 
graphParser.BlockBodyContext = BlockBodyContext; 
graphParser.OneOrGroupedCommandContext = OneOrGroupedCommandContext; 
graphParser.NodeCommandContext = NodeCommandContext; 
graphParser.CycleCommandContext = CycleCommandContext; 
graphParser.StatementContext = StatementContext; 
graphParser.StatementBodyContext = StatementBodyContext; 
graphParser.LogExpressionTypeCommandContext = LogExpressionTypeCommandContext; 
graphParser.DigressionControlContext = DigressionControlContext; 
graphParser.DigressionSpecContext = DigressionSpecContext; 
graphParser.ExitCommandContext = ExitCommandContext; 
graphParser.ReturnCommandContext = ReturnCommandContext; 
graphParser.VariableDefinitionContext = VariableDefinitionContext; 
graphParser.VariableDeclarationContext = VariableDeclarationContext; 
graphParser.TypeDescriptionContext = TypeDescriptionContext; 
graphParser.ConditionalCommandContext = ConditionalCommandContext; 
graphParser.ForeachCommandContext = ForeachCommandContext; 
graphParser.WaitCommandContext = WaitCommandContext; 
graphParser.ActivatedTransitionListContext = ActivatedTransitionListContext; 
graphParser.GotoCommandContext = GotoCommandContext; 
graphParser.SetCommandContext = SetCommandContext; 
graphParser.AssignOpCommandContext = AssignOpCommandContext; 
graphParser.LValueContext = LValueContext; 
graphParser.ExpressionContext = ExpressionContext; 
graphParser.ObjectPropertyExpressionPartContext = ObjectPropertyExpressionPartContext; 
graphParser.ExternalFunctionCallContext = ExternalFunctionCallContext; 
graphParser.BlockFunctionCallContext = BlockFunctionCallContext; 
graphParser.SlotFillingCallContext = SlotFillingCallContext; 
graphParser.BuiltinFunctionCallContext = BuiltinFunctionCallContext; 
graphParser.ArgumentInvokationContext = ArgumentInvokationContext; 
graphParser.ArgumentListContext = ArgumentListContext; 
graphParser.NamedArgumentListContext = NamedArgumentListContext; 
graphParser.PositionalArgumentListContext = PositionalArgumentListContext; 
graphParser.PositionalArgumentContext = PositionalArgumentContext; 
graphParser.NamedArgumentContext = NamedArgumentContext; 
graphParser.ParameterListContext = ParameterListContext; 
graphParser.ParameterContext = ParameterContext; 
graphParser.ReferenceContext = ReferenceContext; 
graphParser.NodeIdContext = NodeIdContext; 
graphParser.BlockIdContext = BlockIdContext; 
graphParser.DigressionIdContext = DigressionIdContext; 
graphParser.TransitionNameContext = TransitionNameContext; 
graphParser.ReferenceNameContext = ReferenceNameContext; 
graphParser.MemberNameContext = MemberNameContext; 
graphParser.TagNameContext = TagNameContext; 
graphParser.FactNameContext = FactNameContext; 
graphParser.FieldNameContext = FieldNameContext; 
graphParser.VariableNameContext = VariableNameContext; 
graphParser.ArgumentNameContext = ArgumentNameContext; 
graphParser.ParameterNameContext = ParameterNameContext; 
graphParser.PropertyNameContext = PropertyNameContext; 
graphParser.BuiltinFunctionNameContext = BuiltinFunctionNameContext; 
graphParser.ExternalFunctionNameContext = ExternalFunctionNameContext; 
graphParser.FunctionNameContext = FunctionNameContext; 
graphParser.AnyNumberContext = AnyNumberContext; 
graphParser.TypeEofContext = TypeEofContext; 
graphParser.Type_Context = Type_Context; 
graphParser.QuestionMarkOptionalContext = QuestionMarkOptionalContext; 
graphParser.ObjectTypeElementSeparatorContext = ObjectTypeElementSeparatorContext; 
graphParser.NonNullableTypeContext = NonNullableTypeContext; 
graphParser.PredefinedTypeContext = PredefinedTypeContext; 
graphParser.PropertySignatureContext = PropertySignatureContext; 
graphParser.IndexSignatureContext = IndexSignatureContext; 
graphParser.TypeAnnotationContext = TypeAnnotationContext; 
graphParser.TypeAliasContext = TypeAliasContext; 
